<template>
<b-container>
  <br>
  <h4>New Post</h4><br>


</b-container>
</template>

<script>
import api from "@/api";

export default {
  name: "Post",
  data() {
    return {
      thread: {
        title: '',
      },
      post: {
        content: '',
      },
      validationError: false,
      validationOutput: ""
    }
  },
  methods: {
    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('title', this.$route.params.id);
      formData.append('title', this.form.title);
      formData.append('document', this.form.document);
      formData.append('category', this.form.category);
      formData.append('description', this.form.description);
      formData.append('default_document', this.form.default_document);
      formData.append('mentor_document', this.form.mentor_document);
      formData.append('mentee_document', this.form.mentee_document);

      if (this.$route.params.id) {
        api.put("documents/" + this.$route.params.id + "/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/documentlist'})
            })
      } else {
        api.post("documents/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/documentlist'})
            })
      }
    }
  }
}
</script>

<style scoped>

</style>