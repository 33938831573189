<template>
  <b-container fluid="xl" class="w-100 mw-100 p-2 m-0">
    <br>
    <b-row>
      <b-col cols="12" class="text-center">
        <b-button variant="fm" class="m-auto mb-3" v-b-modal.new-thread-modal>
          <b-icon icon="journal-plus"></b-icon>
          Add Journal Entry
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="d-flex justify-content-center">

        <div class="timeline">

          <div v-if="journal.length === 0" class="no__journal animated fadeInUp delay-3s timeline__event--type1 notes text-center">
            <div class="timeline__event__content ">Your journal is empty at the moment, get started by adding your first journal entry by hitting the button above.</div>
          </div>

          <JournalEntry v-for="entry in journal" v-bind:key="entry.id"
                        :date="entry.created_at" :type="entry.type" :content="entry.content">
          </JournalEntry>

        </div>

      </b-col>

    </b-row>

    <b-modal size="xl" id="new-thread-modal" title="Login" v-model="modalShow">
      <template #modal-header="">
        <!-- Emulate built in modal header close button action -->
        <h5>New Journal Entry</h5>
      </template>

      <b-form @submit="onSubmit">
        <b-row class="pb-3">
          <b-col cols="8">
            <b-form-group id="input-group-13" label="Type of Journal Entry" label-for="input-13">
              <b-form-select v-model="new_journal_entry.type" class="mb-3">
                <b-form-select-option :value="null" disabled>Please select an option</b-form-select-option>
                <b-form-select-option value="mentormeeting">Mentor Meeting</b-form-select-option>
                <b-form-select-option value="reflection">Reflection</b-form-select-option>
                <b-form-select-option value="notes">Notes</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <p v-if="new_journal_entry.type === 'mentormeeting'"><b>Mentor Meeting: </b>Journal your thoughts after
              catching up with
              your mentor. Perhaps you've discussed a new approach to a challenge, or explored some areas you are
              looking to improve?</p>
            <p v-else-if="new_journal_entry.type === 'reflection'"><b>Reflection: </b>Reflect on your last few days or
              weeks. What went well?
              What challenges did you face? How did you feel about your achievements?</p>
            <p v-else-if="new_journal_entry.type === 'notes'"><b>Notes: </b>Capture any notes relevant to your journey
              as a mentee</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group id="textarea-group" label="Journal" label-for="textarea" description="Minimum 20 characters">
              <b-form-textarea
                  id="textarea"
                  v-model="new_journal_entry.content"
                  placeholder="Add your thoughts here"
                  rows="8"
                  max-rows="15"
                  :state="new_journal_entry.content.length <= 700 && new_journal_entry.content.length >= 20"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <br>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="primary" @click="onSubmit">
          Save
        </b-button>
        <b-button size="md" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
      <br>
    </b-modal>
  </b-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import api from "../api.js";
// eslint-disable-next-line no-unused-vars
import {store} from "@/store";
import JournalEntry from "@/components/JournalEntry.vue";

export default {
  name: "Journal",
  components: {JournalEntry},
  data() {
    return {
      mentee_profile: {
        first_obj: '',
        second_obj: '',
        third_obj: '',
        programme_name: ''
      },
      new_journal_entry: {
        type: '',
        content: ''
      },
      journal: [],
      modalShow: false,
    }
  },
  mounted: function () {
    this.getProfile();
    this.getJournal();
  },
  methods: {
    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      console.log(this.new_journal_entry.type)
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      if (!(this.new_journal_entry.content.length <= 2000 && this.new_journal_entry.content.length >= 20)) {
        this.$parent.makeToast("Please ensure your journal length is at least 20 characters long, and less than 2000 characters");
      }
      else if (this.new_journal_entry.type === '') {
        this.$parent.makeToast("Please select a Journal Type");
      }
      else {
        let formData = new FormData();
        formData.append('type', this.new_journal_entry.type);
        formData.append('content', this.new_journal_entry.content);

        api.post("save_journal/", formData)
            .then(() => {
              this.modalShow = false;
              this.new_journal_entry.type = '';
              this.new_journal_entry.content = '';
              this.getJournal();
            })
      }



    },
    getProfile() {
      api.get("/profile/")
          .then((response) => {
            console.log(response.data)
            this.mentee = response.data.profile.baseuserprofile.mentee;
            this.mentor = response.data.profile.baseuserprofile.mentor;
            this.mentee_profile.first_obj = response.data.menteeprofile.firstObj;
            this.mentee_profile.second_obj = response.data.menteeprofile.secondObj;
            this.mentee_profile.third_obj = response.data.menteeprofile.thirdObj;
            this.mentee_profile.programme_name = response.data.profile.progname;
            this.programme_id = response.data.profile.programme_id
          })
    },
    getJournal() {
      api.get("/get_journal/")
          .then((response) => {
            console.log(response.data)
            this.journal = response.data
            console.log("Journal Length")
            console.log(this.journal.length)
          })
    }
  }

}
</script>

<style scoped>

.card img {
  opacity: 0.7;
}

.card-deck {
  justify-content: flex-start;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-deck .card {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
}

/* Remove default bullets */
ul, #myUL {
  list-style-type: none;
}

/* Remove margins and padding from the parent ul */
#myUL {
  margin: 0;
  padding: 0;
  text-align: left;
}


/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: #52050a1f;
}

.bg-notifications {
  --bs-bg-opacity: 1;
  background-color: #74E66E !important;
}

.border-notifications {
  --bs-border-opacity: 1;
  border-color: #74E66E !important;
}

.bg-journey {
  --bs-bg-opacity: 1;
  background-color: #A67992 !important;
}

.border-journey {
  --bs-border-opacity: 1;
  border-color: #A67992 !important;
}

.bg-forum {
  --bs-bg-opacity: 1;
  background-color: #779176 !important;
}

.border-forum {
  --bs-border-opacity: 1;
  border-color: #779176 !important;
}

.bg-documents {
  --bs-bg-opacity: 1;
  background-color: #66545E !important;
}

.border-documents {
  --bs-border-opacity: 1;
  border-color: #66545E !important;
}

.posted-by {
  margin-bottom: 0px;
  font-size: 10px;
}

.last-post-date {
  margin-bottom: 0px;
  font-size: 14px;
}

.last-post-by {
  margin-bottom: 0px;
  font-size: 12px;
}

.thread-title {
  margin-bottom: 1px;
}

.thread-title a {
  text-decoration: none;
  font-weight: bold;
  color: #832161;
}

.thread-title a:hover {
  color: #ff00a7;
}

.thread {
  background-color: #d2d2d2;
  margin-bottom: 2px;
}

.thread:hover {
  background-color: #b9789d8c;
}

.thread-headers {
  background-color: #e56db1c9;
  padding-top: 3px;
  padding-bottom: 3px;
}

.thread-headers :nth-child(2) {
  text-align: center;
}

.thread div:nth-child(2) {
  text-align: center;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.btn-journal {
  background-color: #840a4dc2;
  color: white;
}


.btn-journal:hover {
  background-color: rgba(192, 16, 113, 0.76);
  color: white;
}


.timeline {
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.timeline__event {
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

.timeline__event__title {
  font-size: 1.5rem;
  line-height: 1.4;
  //text-transform: uppercase;
  font-weight: 400;
  color: #9251ac;
  letter-spacing: 1.5px;
  font-family: "DuniaKita";
}

.timeline__event__content {
  padding: 5px;
}

.timeline__event__date {
  color: #f6a4ec;
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 5px;
  margin-left: 5px;
}

.timeline__event__icon {
  border-radius: 8px 0 0 8px;
  background: #9251ac;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 20%;
  font-size: 2rem;
  color: #9251ac;
  padding: 5px;
}

.timeline__event__icon i {
  position: absolute;
  top: 50%;
  left: -65px;
  font-size: 2.5rem;
  transform: translateY(-50%);
}

.timeline__event__description {
  flex-basis: 60%;
}

.timeline__event:after {
  content: "";
  width: 2px;
  height: 100%;
  background: #9251ac;
  position: absolute;
  top: 52%;
  left: -3.5rem;
  z-index: -1;
}

.timeline__event--type2:after {
  background: #555ac0;
}

.timeline__event--type2 .timeline__event__date {
  color: #87bbfe;
}

.timeline__event--type2 .timeline__event__icon {
  background: #555ac0;
  color: #555ac0;
}

.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}

.timeline__event--type3:before {
  background: #aff1b6;
  border-color: #24b47e;
}

.timeline__event--type3:after {
  background: #24b47e;
}

.timeline__event--type3 .timeline__event__date {
  color: #aff1b6;
}

.timeline__event--type3 .timeline__event__icon {
  background: #24b47e;
  color: #24b47e;
}

.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}

.timeline__event:last-child:after {
  content: none;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
  }

  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }
}

.no__journal {
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  /* display: flex; */
  margin: 10px 0;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

</style>