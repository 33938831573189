<template>
  <div class="banner">
    <p>
      We use cookies to understand how you use this site
      and to help us improve it.
    </p>
    <p>Full details are on our <router-link to="privacypolicy">Privacy Policy</router-link></p>
    <b-btn variant="success" type="button" @click="acceptCookies" class="m-1">Accept</b-btn>
    <b-btn variant="danger" size="sm" type="button" @click="declineCookies" class="m-1">Decline</b-btn>
  </div>
</template>

<script>
import { bootstrap } from 'vue-gtag'

export default {
  name: "CookieBanner",
  methods: {
    acceptCookies() {
      localStorage.setItem('accept_cookies', "1")
      // eslint-disable-next-line no-unused-vars
      bootstrap().then((gtag) => {
        // all done!
      })
      this.$emit("hideBanner")
      location.reload();
    },
    declineCookies() {
      localStorage.setItem('accept_cookies', "0")
      this.$emit("hideBanner")
    }
  }

};
</script>
<style scoped>
.banner {
  position: fixed;
  background-color: rgb(10, 86, 108);
  border: 2px solid #cd659f;
  padding: 10px;
  text-align: center;
  bottom: 10px;
  right: 10px;
  width: 310px;
  color: white;
}

a:hover {
  text-decoration: none !important;
  color: #ff00a5 !important;
}

a {
  text-decoration: none !important;
  color: #ff8ed7 !important;
}

</style>
