<template>
  <b-container>
    <br>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'DocumentList' }" class="fm-link">Documents</b-breadcrumb-item>
        <b-breadcrumb-item active>Manage Document</b-breadcrumb-item>
      </b-breadcrumb>

      <b-form @submit="onSubmit">
        <b-row class="pb-3">
          <b-col cols="8">
            <b-form-group id="input-group-13" label="Document Name" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group id="input-group-13" label="Category" label-for="input-13">
              <b-form-select v-model="form.category" :options="category_options"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pb-3">
          <b-col cols="6">
            <b-form-group id="input-group-13" label="Description" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="form.description"
                  type="text"
                  required
                  placeholder="Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-file
                v-model="form.document"
                :state="Boolean(form.document)"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
            ></b-form-file>
            <div class="mt-3">Selected file: {{ form.document ? form.document.name : '' }}</div>
          </b-col>

        </b-row>
        <b-row>
          <b-col cols="3" class="m-auto">
            <b-form-checkbox v-model="form.default_document" switch size="md">Make Default for all new Programmes?</b-form-checkbox>
          </b-col>
          <b-col cols="3" class="m-auto">
            <b-form-checkbox v-model="form.mentor_document" switch size="md">Mentor Document?</b-form-checkbox>
          </b-col>
          <b-col cols="3" class="m-auto">
            <b-form-checkbox v-model="form.mentee_document" switch size="md">Mentee Document?</b-form-checkbox>
          </b-col>
          <b-col cols="3" class="m-auto">
            <b-form-checkbox v-model="form.apply_to_existing" switch size="md">Apply to existing Programmes?</b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="pb-3 float-end">
          <b-col>
            <b-button class="mr-2" type="submit" variant="primary">Save</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>

</template>

<script>
import api from '../../api.js'

export default {
  // props: ['id'],
  data() {
    return {
      form: {
        name: '',
        document: '',
        category: '',
        description: '',
        default_document: false,
        mentor_document: false,
        mentee_document: false,
        apply_to_existing: false,
      },
      category_options: [
        { value: null, text: 'Please select an option' , disabled: true  },
        { value: 'agreement', text: 'Agreement' },
        { value: 'training', text: 'Training' },
        { value: 'resource', text: 'Resource' }
      ],

      validationError: false,
      validationOutput: ""
    }
  },

  mounted: function () {
    // this.getCars();
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      this.getDocument(this.$route.params.id);
    }
  },
  methods: {
    getDocument(id) {
      console.log("getting programme details");
      api.get("/documents/" + id)
          .then((response) => {
            console.log(response.data);
            this.form.name = response.data.name;
            this.form.document = response.data.document;
            this.form.category = response.data.category;
            this.form.description = response.data.description;
            this.form.default_document = response.data.default_document;
            this.form.mentor_document = response.data.mentor_document;
            this.form.mentee_document = response.data.mentee_document;
            this.form.apply_to_existing = response.data.apply_to_existing;
          })
    },
    handleClick(record, target) {
      console.log(record);
      this.$router.push({name: target, params: {id: record, auc_id: this.$route.params.id}})
    },


    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('id', this.$route.params.id);
      formData.append('name', this.form.name);
      formData.append('document', this.form.document);
      formData.append('category', this.form.category);
      formData.append('description', this.form.description);
      formData.append('default_document', this.form.default_document);
      formData.append('mentor_document', this.form.mentor_document);
      formData.append('mentee_document', this.form.mentee_document);
      formData.append('apply_to_existing', this.form.apply_to_existing);
      if (this.$route.params.id) {
        api.put("documents/" + this.$route.params.id + "/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/documentlist'})
            })
      } else {
        api.post("documents/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/documentlist'})
            })
      }
    }
  }
}
</script>
<style>

</style>