<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <p class="fm-header-text">Privacy Policy</p>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4">
        <b-col cols="12" class="about-text">
          <p>This notice explains what information I, Beki Osborne, may collect about you and what it will be used
            for.</p>

          <p>I, Beki Osborne, am a “data controller” of the personal data that you provide to us. This means that I am
            responsible for deciding how I hold and use personal information about you. I am required under data
            protection legislation to notify you of the information contained in this privacy notice.</p>

          <p>If you have any questions about this privacy notice, then you are welcome to get in touch to discuss these
            further with me via the contact form on my website.</p>

          <p>I will comply with data protection legislation (the Data Protection Act 2018 and the General Data
            Protection Regulation (GDPR)). This says that the personal information I hold about you must be:</p>

          <ul>
            <li>Used lawfully, fairly and in a transparent way.</li>
            <li>Collected only for valid purposes that we have clearly explained to you and not used in any way that is
            incompatible with those purposes.</li>
            <li>Relevant to the purposes we have told you about and limited only to those purposes.</li>
            <li>Accurate and kept up to date.</li>
            <li>Kept only as long as necessary for the purposes we have told you about.</li>
            <li>Kept securely.</li>
          </ul>

          <p>If you are visiting my website, I use cookies to store data related to your session or use of the programme, these are essential
            cookies required in the act of providing this service.</p>

          <p>We use Google Analytics to collect information about the use of our site to understand usage patterns, engagement and
            user experience. Google Analytics collects information such as your IP address, device type, operating system, referring URLs, location
            and pages visited. You can opt-out of this by declining
          our cookie notice when first visiting my site. You can clear cookies from your browser cache and refresh to present this again. Google also offer the ability to opt out of analytics across the web
          by installing a browser add-on, available here - <a href="https://tools.google.com/dlpage/gaoptout/">https://tools.google.com/dlpage/gaoptout/</a></p>

          <p>Please note that if you follow a link from this site to another site, this policy will no longer apply. I
            am not responsible for other sites’ information handling practices. Use of your information by the owner of
            the linked site will be governed by that site’s privacy policy, which I encourage you to read.</p>

          <p>If you submit a contact form on our site you opt in to providing us your name, email address and organisation.
          This information will be used for the purpose of acting on the contact form and will be regularly deleted.</p>

          <strong>If you sign up for my mailing list</strong>

          <p>If you sign up to my mailing list, I will ask for your name and email address. This information will only
            be used to send you information you have subscribed to and will not be passed to a third party. All mailing
            list data is held securely on my ConvertKit system.</p>

          <strong>For feminist mentoring programmes</strong>

          <p>If you have applied for this programme, you will have provided me with your name and contact details to
            process your application.</p>

          <p>Your name, email address, objectives and mentee or mentor profile will be saved and used on our Feminist mentoring
          platform for the purpose of delivering the programme and tracking your progress.
          Accounts and all historical data will be deleted after 1 year of no login activity.</p>

          <p>Information from the Feminist Mentoring Programme, including your application form and any feedback
            provided, may be shared:</p>

            <ul>
              <li>With potential mentors/mentees (for matching purposes)</li>
              <li>Anonymously with your member organisation (for administration and evaluation purposes)</li>
            </ul>
            <p>I reserve the right to update this privacy policy at any time, and will make amended versions of this notice
            available on my website when I make changes to it. I may also notify you in other ways from time to time
            about the processing of your personal information. If you have any queries, please don’t hesitate to reach
            out via my contact form or look up the UK’s laws and guidance on data protection and how to report concerns
            and breaches here: <a href="https://ico.org.uk/">https://ico.org.uk/</a></p>


        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>

export default {
  name: "Beki Osborne - Privacy Policy",
  title: "Beki Osborne - Privacy Policy",
  data() {
    return {
      title: "Beki Osborne - Privacy Policy"
    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>

<style scoped>

.about-text {
  margin: auto;
  padding-top: 30px;
}

.about-image {
  text-align: center;
}

.image-border {
  border-radius: 30px;
  display: block;

  background-color: rgba(0, 0, 0, 0.72);
  border: 2px solid #012A36;
  margin: auto;
}

.fm-col{
  background-color: rgba(1, 42, 54, 0.11);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
}

.blockquote {
  background-color: rgba(222, 107, 172, 0.24);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}

a.fm-link {
  text-decoration: none !important;
  color: #832161 !important;
}

a.fm-link:hover {
  text-decoration: none !important;
  color: #c72c93 !important;
}

.page-header {
  background-color: rgb(1, 42, 54);
  width: fit-content;
  color: #e66eb2;
  margin: 30px 0px 30px 30px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  background-image: url('/static/images/about-header.jpg');
}




*,:after,:before{box-sizing:border-box}
.pull-left{float:left}
.pull-right{float:right}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}

.author-quote-wrap .author-quote,
.author-quote-wrap .author-photo:before{
  top:0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
}

.author-quote-wrap{
  color:#fff;
  width:100%;
  overflow:hidden;
  max-width:560px;
  min-height:390px;
  margin:50px auto 0;
  background:#832161;
  position:relative;
  box-shadow:0 16px 28px 0 rgba(0,0,0,.22),0 25px 55px 0 rgba(0,0,0,.21);
}
.author-quote-wrap .author-quote{
  display:none;
}
.author-quote-wrap .toggle-quote{
  display:none;
}
.author-quote-wrap .toggle-quote:checked + .author-quote{
  display:block;
}
.author-quote-wrap .author-quote a{
  color:inherit;
  text-decoration:none;
}
.author-quote-wrap .author-photo,
.author-quote-wrap .quote-content{
  height:100%;
  position:relative;
}
.author-quote-wrap .author-photo{
  width:140px;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  quotes:'\201C''\201D''\2018''\2019';
}

.author-quote-wrap .author-photo:before{
  content:'';
  background: rgb(215, 105, 166);
}
.author-quote-wrap .author-photo:after{
  top:15px;
  left:60px;
  font-size:72px;
  line-height:72px;
  position:absolute;
  font-family:serif;
  content:open-quote;
}





.author-quote-wrap .quote-content{
  padding:15px;
  width:calc(560px - 140px);
}

.quote-content .quote-like{
  font-size:12px;
  font-weight:400;
  padding:4px 8px;
  border-radius:15px;
  display:inline-block;
  background:rgba(0,0,0,.2);
}
.quote-content .quote-like .love{
  width:18px;
  height:10px;
  position:relative;
  display:inline-block;
}
.quote-content .quote-like .love:before,
.quote-content .quote-like .love:after {
  top:0;
  left:7px;
  content:'';
  width:7px;
  height:12px;
  background:#fff;
  position:absolute;
  transform:rotate(-45deg);
  transform-origin:0 100%;
  border-radius:20px 20px 0 0;
}
.quote-content .quote-like .love:after {
  left:0;
  transform:rotate(45deg);
  transform-origin :100% 100%;
}


.quote-content .quote-text{
  font-size:30px;
  font-weight:900;
  line-height:55px;
}
.quote-content .quote-author{
  bottom:15px;
  font-size:14px;
  font-weight:500;
  position:absolute;
}
.quote-content .quote-author:before{
  content:'- ';
}

.container-xl {
  font-size: 20px;
}

.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0.1), rgba(2, 43, 54, 0.1)), url('/static/images/pp-header.jpg') no-repeat right;
  background-size: cover;
}

</style>