<template>
  <b-container>
    <br>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'Forum' }" class="fm-link">Forum</b-breadcrumb-item>
      <b-breadcrumb-item active>Thread</b-breadcrumb-item>
    </b-breadcrumb>
    <h4>{{ threadTitle }}</h4>
    <b-row class="p-3">
    <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
      <b-button-group class="mx-1">
        <b-button size="sm" v-b-modal.post-reply-modal variant="fm">Post Reply</b-button>
      </b-button-group>
    </b-button-toolbar>
    </b-row>
    <b-row class="post mt-1" v-for="(value, id) in this.posts" :key="id">
      <b-col class="post-header" cols="12">
        <p class="thread-title" v-if="id === 0">{{ threadTitle }}</p>
        <p class="thread-title" v-if="id != 0">RE: {{ threadTitle }}</p>
        By <span class="author">{{ value.author }}</span> | <span class="created-date">{{ value.created_at }}</span>
        <p class="post-content">{{ value.content }}</p>
        <b-button-toolbar class="post-toolbar" aria-label="Toolbar with post controls">
          <b-button-group size="sm" class="mr-1">
            <b-button v-b-modal.report-post-modal @click="setReportPostId(value.id)"><b-icon icon="flag"></b-icon>  Report Post</b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-row class="p-3">
      <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
        <b-button-group class="mx-1">
          <b-button size="sm" v-b-modal.post-reply-modal variant="fm">Post Reply</b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-row>


    <b-modal size="lg" id="post-reply-modal" title="Login" v-model="modalShow">
      <template #modal-header="">
        <h5>Post Reply</h5>
      </template>

      <b-form @submit="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group id="textarea-group" label="Content" label-for="textarea">
              <b-form-textarea
                  id="textarea"
                  v-model="reply.content"
                  placeholder="Enter post contents"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <!--      <b-row class="pb-3 float-start mt-4">-->
        <!--        <b-col>-->
        <!--          <b-button class="mr-2" type="submit" variant="primary">Save</b-button>-->
        <!--        </b-col>-->
        <!--      </b-row>-->
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="primary" @click="onSubmit">
          Save
        </b-button>
        <b-button size="md" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
      <br>
    </b-modal>

    <b-modal size="lg" id="report-post-modal" title="Report Post" v-model="reportModalShow">
      <template #modal-header="">
        <h5>Report Post</h5>
      </template>

      <b-form @submit="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group id="textarea-group" label="Reason" label-for="textarea">
              <b-form-textarea
                  id="textarea"
                  v-model="report.report_reason"
                  placeholder="Enter reason for reporting post"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <!--      <b-row class="pb-3 float-start mt-4">-->
        <!--        <b-col>-->
        <!--          <b-button class="mr-2" type="submit" variant="primary">Save</b-button>-->
        <!--        </b-col>-->
        <!--      </b-row>-->
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="primary" @click="reportPost">
          Submit Report
        </b-button>
        <b-button size="md" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
      <br>
    </b-modal>

  </b-container>
</template>

<script>
import api from "@/api";

export default {
  name: "Thread",
  data() {
    return {
      threadId: '',
      threadTitle: '',
      posts: [],
      reply: {
        content: ''
      },
      report: {
        report_reason: '',
        post_id: ''
      },
      modalShow: false,
      reportModalShow: false
    }
  },
  mounted: function () {
    this.threadId = this.$route.params.threadId
    this.threadTitle = this.$route.params.threadTitle
    this.getPosts();
  },
  methods: {
    setReportPostId(id) {
      this.report.post_id = id;
    },
    getPosts() {
      api.get("/posts/"+this.threadId+"/")
          .then((response) => {
            console.log(response.data.post_set)
            this.posts = response.data.post_set;
          })
    },
    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('thread', this.threadId);
      formData.append('post', this.reply.content);

      api.post("post/", formData)
          .then(() => {
            this.getPosts();
            this.modalShow = false;
            this.reply.content = '';
          })
    },
    reportPost(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('post_id', this.report.post_id);
      formData.append('reason', this.report.report_reason);

      api.post("reportpost/", formData)
          .then(() => {
            this.getPosts();
            this.reportModalShow = false;
            this.report.report_reason = '';
            this.report.post_id = '';
            this.$parent.makeToast("Post reported. An admin will review and determine if any action needs to be taken");
          })
    }
  }
}
</script>

<style scoped>
.post {
  padding: 10px;
}
.post:nth-child(odd) {
  background-color: #d2d2d2;
}

.post:nth-child(even) {
  background-color: #bdbdbd;
}
.thread-title {
  margin-bottom: 0px;
  font-size: 14px;
  color: #832161;
}

.author {
  margin-bottom: 0px;
  font-size: 12px;
  color: #832161;
  font-weight: bold;
}

.created-date {
  margin-bottom: 0px;
  font-size: 12px;
}

.post-content {
  margin-bottom: 0px;
  margin-top: 10px;
  font-size: 16px;
  white-space: pre-wrap;
  word-wrap: break-word;

}

.post-header {
  font-size: 12px;
}

.post .btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.1rem;
  --bs-btn-padding-x: 0.3rem;
  --bs-btn-font-size: 0.8rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.post-toolbar {
  float: right;
}

.btn .b-icon.bi, .nav-link .b-icon.bi, .dropdown-toggle .b-icon.bi, .dropdown-item .b-icon.bi, .input-group-text .b-icon.bi {
  font-size: 100%;
  vertical-align: text-bottom;
}
</style>