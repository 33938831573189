<template>
    <b-navbar class="footer-navbar">

      <b-col align="center">
        © 2024 Copyright
        <router-link to="/" class="p-2">Beki Osborne</router-link>
        <router-link to="feminist-mentoring" class="p-2">Feminist Mentoring</router-link>
        <router-link to="privacypolicy" class="p-2">Privacy Policy</router-link>
      </b-col>
    </b-navbar>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style lang="css" scoped>
.footer-navbar a {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
}

.footer-navbar a:hover {
  color: #d566a5;
  text-decoration: none;
}

.footer-navbar {
  font-size: 14px;
  color: #000000;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  bottom: 0;
  left: 0;
  right: 0;

}


</style>
