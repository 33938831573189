<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="container-fluid container-fluid-max">
      <b-row class="pt-4">
        <b-col cols="12" class="text-center">
          <br>
          I'm sorry, that page doesn't exist
          <br><br><br>
        </b-col>
    </b-row>
    </div>
  </b-container>
</template>

<script>

export default {
  name: "404",
  data() {
    return {
      title: "404"
    }
  },
  mounted: function () {

  },
  methods: {

  }
}
</script>

<style scoped>

.about-text {
  margin: auto;
  padding-top: 30px;
}

.about-image {
  text-align: center;
}

.image-border {
  border-radius: 30px;
  display: block;

  background-color: rgba(0, 0, 0, 0.72);
  border: 2px solid #012A36;
  margin: auto;
}

.fm-col{
  background-color: rgba(1, 42, 54, 0.11);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
}

.blockquote {
  background-color: rgba(222, 107, 172, 0.24);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}

a.fm-link {
  text-decoration: none !important;
  color: #832161 !important;
}

a.fm-link:hover {
  text-decoration: none !important;
  color: #c72c93 !important;
}

.page-header {
  background-color: rgb(1, 42, 54);
  width: fit-content;
  color: #e66eb2;
  margin: 30px 0px 30px 30px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  background-image: url('/static/images/about-header.jpg');
}




*,:after,:before{box-sizing:border-box}
.pull-left{float:left}
.pull-right{float:right}
.clearfix:after,.clearfix:before{content:'';display:table}
.clearfix:after{clear:both;display:block}

.author-quote-wrap .author-quote,
.author-quote-wrap .author-photo:before{
  top:0;
  left:0;
  right:0;
  bottom:0;
  position:absolute;
}

.author-quote-wrap{
  color:#fff;
  width:100%;
  overflow:hidden;
  max-width:560px;
  min-height:390px;
  margin:50px auto 0;
  background:#832161;
  position:relative;
  box-shadow:0 16px 28px 0 rgba(0,0,0,.22),0 25px 55px 0 rgba(0,0,0,.21);
}
.author-quote-wrap .author-quote{
  display:none;
}
.author-quote-wrap .toggle-quote{
  display:none;
}
.author-quote-wrap .toggle-quote:checked + .author-quote{
  display:block;
}
.author-quote-wrap .author-quote a{
  color:inherit;
  text-decoration:none;
}
.author-quote-wrap .author-photo,
.author-quote-wrap .quote-content{
  height:100%;
  position:relative;
}
.author-quote-wrap .author-photo{
  width:140px;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  quotes:'\201C''\201D''\2018''\2019';
}

.author-quote-wrap .author-photo:before{
  content:'';
  background: rgb(215, 105, 166);
}
.author-quote-wrap .author-photo:after{
  top:15px;
  left:60px;
  font-size:72px;
  line-height:72px;
  position:absolute;
  font-family:serif;
  content:open-quote;
}





.author-quote-wrap .quote-content{
  padding:15px;
  width:calc(560px - 140px);
}

.quote-content .quote-like{
  font-size:12px;
  font-weight:400;
  padding:4px 8px;
  border-radius:15px;
  display:inline-block;
  background:rgba(0,0,0,.2);
}
.quote-content .quote-like .love{
  width:18px;
  height:10px;
  position:relative;
  display:inline-block;
}
.quote-content .quote-like .love:before,
.quote-content .quote-like .love:after {
  top:0;
  left:7px;
  content:'';
  width:7px;
  height:12px;
  background:#fff;
  position:absolute;
  transform:rotate(-45deg);
  transform-origin:0 100%;
  border-radius:20px 20px 0 0;
}
.quote-content .quote-like .love:after {
  left:0;
  transform:rotate(45deg);
  transform-origin :100% 100%;
}


.quote-content .quote-text{
  font-size:30px;
  font-weight:900;
  line-height:55px;
}
.quote-content .quote-author{
  bottom:15px;
  font-size:14px;
  font-weight:500;
  position:absolute;
}
.quote-content .quote-author:before{
  content:'- ';
}

.container-xl {
  font-size: 20px;
}

.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0.1), rgba(2, 43, 54, 0.1)), url('/static/images/rcew-header.jpg') no-repeat right;
  background-size: cover;
}

</style>
