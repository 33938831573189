<template>
  <b-container>
    <br>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'NotificationList' }" class="fm-link">Notifications</b-breadcrumb-item>
        <b-breadcrumb-item active>Manage Surveys</b-breadcrumb-item>
      </b-breadcrumb>

      <b-form @submit="onSubmit">
        <b-row class="pb-3">
          <b-col cols="4">
            <b-form-group id="input-group-13" label="Survey" label-for="input-13">
              <b-form-select v-model="form.survey" :options="survey_list"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="3">
            <b-form-group id="input-group-13" label="Notification Headline" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="form.notification_headline"
                  type="text"
                  required
                  placeholder="Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="5">
            <b-form-group id="input-group-13" label="Notification Text" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="form.notification_text"
                  type="text"
                  required
                  placeholder="Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <b-button class="mr-2" type="submit" variant="primary">Save</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-container>

</template>

<script>
import api from '../../api.js'

export default {
  // props: ['id'],
  data() {
    return {
      form: {
        notification_headline: '',
        notification_text: '',
        notification_type: '',
        survey: ''
      },
      survey_list : [],
      validationError: false,
      validationOutput: ""
    }
  },

  mounted: function () {
    this.getSurveys()
    // console.log(this.$route.params.id);
    if (this.$route.params.id) {
      this.getNotification(this.$route.params.id);
    }
  },
  methods: {
    getNotification(id) {
      console.log("getting programme details");
      api.get("/notifications/" + id)
          .then((response) => {
            console.log(response.data);
            this.form.notification_headline = response.data.headline;
            this.form.notification_text = response.data.text;
            this.form.notification_type = "survey";
            this.form.survey = response.data.form;
          })
    },
    getSurveys() {
      api.get("/surveys/")
          .then((response) => {
            console.log(response.data);
            this.survey_list = response.data

          })

    },
    handleClick(record, target) {
      console.log(record);
      this.$router.push({name: target, params: {id: record, auc_id: this.$route.params.id}})
    },


    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('id', this.$route.params.id);
      formData.append('headline', this.form.notification_headline);
      formData.append('text', this.form.notification_text);
      formData.append('type', 'survey');
      formData.append('survey', this.form.survey);
      if (this.$route.params.id) {
        api.put("save_notification/" + this.$route.params.id + "/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/notificationlist'})
            })
      } else {
        api.post("save_notification/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/notificationlist'})
            })
      }
    }
  }
}
</script>
<style>

</style>