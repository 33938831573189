<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <b-container fluid="xl">
      <div class="accordion m-4" role="tablist">
        <h4>Your Profile</h4>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-1 variant="fm">Change Password</b-button>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <form>
                <label for="password1">New Password</label>
                <ValidationProvider rules="password-min|password-upper|password-special" v-slot="{ errors }" :bails="false">
                  <b-form-input type="password" id="password1" autocomplete="new-password"
                                aria-describedby="password-help-block" v-model="form.password1"
                                class="w-75 mb-3"></b-form-input>
                  <ul>
                    <li class="input-error" v-bind:key="error" v-for="error in errors">{{ error }}</li>
                  </ul>
                </ValidationProvider>
                <label for="password2">Confirm New Password</label>
                <b-form-input type="password" id="password2" autocomplete="new-password"
                              aria-describedby="password-help-block" v-model="form.password2"
                              class="w-75 mb-3"></b-form-input>
                <b-button size="md" variant="success" @click="changepassword" type="submit" class="m-2">
                  Update
                </b-button>
              </form>
            </b-card-body>
          </b-collapse>
        </b-card>

      </div>
      <div class="accordion m-4" role="tablist">
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="fm">Email Notifications</b-button>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <form>
                  <b-form-checkbox
                      id="email_forum_digest_weekly"
                      v-model="form.email_forum_digest_weekly"
                      name="email_forum_digest_weekly"
                      class="checkbox-label"
                  >
                    I am happy to receive a weekly forum activity summary email
                  </b-form-checkbox>

                <b-button size="md" variant="success" @click="saveEmailPref" type="submit" class="m-2">
                  Save
                </b-button>
              </form>
            </b-card-body>
          </b-collapse>
        </b-card>

      </div>
    </b-container>
  </b-container>
</template>

<script>
import api from "@/api";
import {ValidationProvider, extend} from 'vee-validate';

extend('password-min', value => {
  if (value.length >= 8) {
    return true;
  }
  return 'Password must be at least 8 characters long';
});
extend('password-upper', value => {
  if (value.search(/[A-Z]/) > -1) {
    return true;
  }
  return 'Password must contain at least one uppercase character';
});
extend('password-special', value => {
  if (value.search(/[@#$%^&*]/) > -1) {
    return true;
  }
  return 'Password must contain at least one of these special characters @#$%^&*';
});

export default {
  components: {
    ValidationProvider
  },
  name: "profile",
  data() {
    return {
      form: {
        password1: '',
        password2: '',
        email_forum_digest_weekly: null
      }
    }
  },
  mounted: function() {
    this.getProfile();
  },
  methods: {
    changepassword(evt) {
      if (evt) {
        evt.preventDefault()
      }
      let formData = new FormData();
      if (this.form.password1 === this.form.password2) {
        formData.append('password1', this.form.password1);
        formData.append('password2', this.form.password2);

        api.post("changepassword/", formData)
            .then(() => {
              this.$parent.makeToast("Password successfully updated");
              this.form.password1 = "";
              this.form.password2 = "";
              this.$root.$emit('bv::toggle::collapse', 'accordion-1')
            })
      } else {
        this.$parent.makeToast("Passwords don't match, please ensure both passwords are the same");
      }

    },
    saveEmailPref(evt) {
      if (evt) {
        evt.preventDefault()
      }
      let formData = new FormData();
      formData.append('email_forum_digest_weekly', this.form.email_forum_digest_weekly);

      api.post("email_pref/", formData)
          .then(() => {
            this.$parent.makeToast("Email Preferences successfully updated");
            this.$root.$emit('bv::toggle::collapse', 'accordion-2')
          })
    },
    getProfile() {
      api.get("/profile/")
          .then((response) => {
            this.form.email_forum_digest_weekly = response.data.profile.baseuserprofile.email_forum_digest_weekly;
          })
    }
  }
}
</script>

<style scoped>

</style>