<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <h1 class="fm-header-text">Hi there, I'm Beki Osborne</h1>
    </div>
    <b-container fluid="xl">
        <b-row class="pt-4 mt-4">
          <b-col lg="4" class="m-auto">
            <b-img src="/static/images/beki-fp.jpg" alt="Photo of Beki smiling while standing in a field of sunflowers"  fluid></b-img><br>
          </b-col>
          <b-col lg="7" class="intro-text">
            <h2>A Violence Against Women and Girls (VAWG) sector leader, with experience in public and third sectors.</h2>
            <br><br>
            <p>My area of focus is on how we can develop and retain talent within social movements. I believe that all teams and organisations should prioritise professional development to transform their work and improve job satisfaction and employee wellbeing.</p>

            <p>Everything I do is grounded in an intersectional feminist approach to challenge structures and bring new energy, perspectives and ideas to the table to deliver opportunities for improvement and innovation.</p>

            <p>Outside of work, I’m a mentor, an advocate for extending our gaze beyond our own doorstep, a netball enthusiast and – if nothing else – an escapist who loves to laugh with friends and family, spend time with my husband, dog and two cats and wind down with some terrible reality TV, or a good book or film.</p>
            <br>
            <p>Have a gander, and feel free to get in touch.</p>
            <br>
          </b-col>
        </b-row>

          <b-row style="text-align:center;" class="mb-4">
            <b-col cols="2"></b-col>
            <b-col cols="8">
              <br>
              Please note! This website contains my personal views, which may differ from an organisation or
              professional body that I am affiliated with. To learn more about an organisation, please go to their
              website directly.
            </b-col>
            <b-col cols="2"></b-col>
          </b-row>
      </b-container>

  </b-container>
</template>

<script>

export default {
  name: "Home",
  metaInfo: function () {
    return {
      title: "Beki Osborne - Feminist Mentoring & Consulting",
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: "Feminist Mentoring programmes and consulting for organisations, networks and community groups who seek to holistically develop their members and provide safe spaces"},
        { name: 'og:site_name', content: "Beki Osborne - Feminist Mentoring & Consulting"},
        { name: 'og:title', content: "Beki Osborne - Feminist Mentoring & Consulting"},
        { name: 'og:url', content: "https://www.bekiosborne.com/"},
        { name: 'og:type', content: "website"}
      ]
    }
  }
}
</script>

<style scoped>

img {
  border-radius: 10px;
  display: flex;

  border: 2px solid #012A36;
  margin: auto;
}

.intro-text {
  margin: auto;
  font-size: 20px;
}

.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/fp-header.jpg') no-repeat right;
  background-size: cover;
}

</style>
