import Vue from 'vue'
import App from './App.vue'
// import {makeServer} from "./server"
import router from './router/index.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookies from 'vue-cookies'
import { store } from "@/store"
import api from './api.js'
import pageTitle from './_mixins/pageTitle'
// This line will always build mirage, switch out to dev only mode above when ready
// makeServer()
// import 'vue-awesome/icons'
// import Icon from 'vue-awesome/components/Icon'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMeta from 'vue-meta'
import VueGtag from "vue-gtag";

Vue.use(VueMeta)

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
// Vue.component('v-icon', Icon)

Vue.mixin(pageTitle)


Vue.$http = api;
// api.defaults.timeout = 500000;
//
// api.interceptors.request.use(function (config) {
//   // console.log("headers "+config.headers);
//   // console.log(config);
//   if ( config.url === 'register/' ) {
//     return config;
//   } else if ( store.state.isAuthenticated) {
//     const token = Vue.$cookies.get('access');
//     config.headers.Authorization =  `Bearer ${token}`;
//     // if we are still authorized but user isn't saved, user may have refreshed the page. populate Vuex
//     // from localstorage. IF statement avoids this running each time we perform a get/post and repopulating the store
//     if (store.state.authUser == undefined){
//       store.commit("setAuthUser",
//           {authUser: localStorage.getItem('authUser'), isAdmin: localStorage.getItem('isAdmin'), isAuthenticated: localStorage.getItem('isAuthenticated'),
//             access: localStorage.getItem('access'), refresh: localStorage.getItem('refresh')})
//     }
//
//   } else if (localStorage.getItem('access') !== null || localStorage.getItem('access') !== undefined || localStorage.getItem('access') !== 'undefined') {
//     console.log("We have a token saved but not in Vuex");
//     const token = localStorage.getItem('access');
//     config.headers.Authorization =  `Bearer ${token}`;
//     store.commit("setAuthUser",
//         {authUser: localStorage.getItem('authUser'), isAdmin: localStorage.getItem('isAdmin'), isAuthenticated: localStorage.getItem('isAuthenticated'),
//           access: localStorage.getItem('access'), refresh: localStorage.getItem('refresh')})
//   }
//   return config;
// });
//
// api.interceptors.response.use(
//     response => {
//       if (response.status === 200 || response.status === 201) {
//         return Promise.resolve(response);
//       } else if(response.status === 401) {
//         console.log("RESPONSE - session expired");
//         Vue.$cookies.remove('access');
//         // localStorage.removeItem('access')
//         // localStorage.removeItem('refresh')
//         // localStorage.removeItem('authUser')
//         // localStorage.removeItem('isAuthenticated')
//         // localStorage.removeItem('isAdmin')
//         store.commit("setAuthUser",
//             {authUser: null, isAdmin: null, isAuthenticated: false, access: null, refresh: null})
//         router.replace({
//           path: "/login",
//           query: { redirect: router.currentRoute.fullPath }
//         });
//         return Promise.reject(response);
//       }
//     },
//     error => {
//       if (error.response.status) {
//         console.log(error.status);
//         switch (error.response.status) {
//           case 400:
//             break;
//
//           case 401:
//             console.log("ERROR - session expired");
//
//             Vue.$cookies.remove('token');
//             store.commit("setAuthUser",
//                 {authUser: null, isAuthenticated: false, isAdmin: false})
//             router.replace({
//               path: "/login",
//               query: { redirect: router.currentRoute.fullPath }
//             });
//
//             break;
//           case 403:
//             router.replace({
//               path: "/login",
//               query: { redirect: router.currentRoute.fullPath }
//             });
//             break;
//           case 404:
//             alert('page not exist');
//             break;
//           case 502:
//             setTimeout(() => {
//               router.replace({
//                 path: "/login",
//                 query: {
//                   redirect: router.currentRoute.fullPath
//                 }
//               });
//             }, 1000);
//         }
//         return Promise.reject(error.response);
//       }
//     }
// );
if(process.env.NODE_ENV === "production") {
  // eslint-disable-next-line no-unused-vars
  Vue.use(VueGtag, {
    config: { id: "G-L6BPQNSXT5" },
    bootstrap: false
  }, router);
}


new Vue({
  render: h => h(App),
  router,
  components: {App},
  store,
}).$mount('#app')

Vue.$cookies.config('7d')

