<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <p class="fm-header-text">Feminist Mentoring Programme</p>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4">
        <b-col cols="12" class="about-text">


          <p>Following success of the pilot Feminist Mentoring Programme in 2023, Beki Osborne and Rape Crisis England
            and Wales are delighted to announce the return of the programme for 2024.</p>
          <p>Staff, volunteers and trustees across Rape Crisis England and Wales and member centres can apply to be
            mentored or receive mentoring from another woman or individual across the network.</p>
<!--          <blockquote>My experience with the feminist mentoring scheme has been positive, and as someone who was new to-->
<!--            working within the Rape Crisis network, it really helped me to feel integrated into the community. I have-->
<!--            been able to explore different scenarios and talk these through with someone who is experienced, who has-->
<!--            helped me to feel empowered in my approach. The mentoring sessions have helped to increase my confidence,-->
<!--            both in working with clients and also with my own self-care, reflecting that I need to be at my best to-->
<!--            provide the best service I can for others. It has also been interesting to see how another Centre works, and-->
<!--            how we could each learn from the other.-->
<!--            <span>Selina Stroud, ChISVA, Cambridge Rape Crisis</span></blockquote>-->

          <p>The programme is run by Beki Osborne (who is also CEO at Sexual Assault and Abuse Support Service
            Buckinghamshire and Milton Keynes) and participants will receive full training and support to take part.</p>

          <b-card-group>
            <b-card
                border-variant="journey"
                header="Mentoring"
                header-bg-variant="journey"
                header-border-variant="journey"
                header-text-variant="black"
                align="center"
                class="m-2"
            >
              <b-card-text>
                This is a process to allow individuals to develop skills and gain knowledge in areas that interest them
                with guidance from a mentor who has more experience in that area. Mentees can benefit from learning
                about the path their mentor has taken to get them to where they are today, and apply this to their own
                situation to help them to reach their goals.
              </b-card-text>
            </b-card>
            <b-card
                border-variant="journey"
                header="Feminist mentoring"
                header-bg-variant="journey"
                header-border-variant="journey"
                header-text-variant="black"
                align="center"
                class="m-2"
            >
              <b-card-text>
                We explore the power dynamics and structures we work and live in to create a space for individuals to
                support one another in combatting any oppression that may feel limiting in order for us all to reach our
                potential. With an intersectional lens, we will value and honour each person’s diverse lived experiences
                and create mutually beneficial relationships.
              </b-card-text>
            </b-card>
          </b-card-group>
          <br>
          <div id="fm-quotes_wrapper">
            <h4>
              Quotes from past participants
            </h4>
            <div class="fm-quotes" id="fm-quotes">
              <div class="item">
                <blockquote>As a feminist mentor within Rape Crisis, my experience supporting a fellow colleague has
                  been
                  incredibly fulfilling. Providing guidance and support on her journey towards career progression within
                  our
                  organisation has been both rewarding and enriching. Firstly, the training we as mentors were given was
                  great,
                  and provided us with many great tools to support the process. I deeply valued the space created by our
                  mentorship
                  program, as it allowed me to support a colleague in navigating her path within a feminist framework.
                  Through this process, I have had the opportunity to reflect on what development and growth within a
                  feminist
                  organisation mean to me personally. The program has been exceptionally well-organized, providing us
                  with
                  meaningful spaces for engaging and fulfilling conversations to take place. Overall, I am grateful for
                  the
                  opportunity to mentor and be part of such a valuable initiative within our community.
                  <span>Heather Conboy, Therapeutic Team and Service Development Manager, KCRASAC</span></blockquote>
              </div>
              <div class="item">
                <blockquote>My experience with the feminist mentoring scheme has been positive, and as someone who was
                  new to working within the Rape Crisis network, it really helped me to feel integrated into the
                  community. I have been able to explore different scenarios and talk these through with someone who is
                  experienced, who has helped me to feel empowered in my approach. The mentoring sessions have helped to
                  increase my confidence, both in working with clients and also with my own self-care, reflecting that I
                  need to be at my best to provide the best service I can for others. It has also been interesting to
                  see how another Centre works, and how we could each learn from the other.
                  <span>Selina Stroud, ChISVA, Cambridge Rape Crisis</span></blockquote>
              </div>
              <div class="item">
                <blockquote>I thoroughly enjoyed taking part in the feminist mentoring programme and I would like to
                  take part in future editions. As a mentor, it validated my professional experience and skills. It also
                  helped me to strongly challenge the imposter syndrome that occasionally creeps up on me. Furthermore,
                  it provided me with the opportunity to transfer skills from previous roles in education to a new
                  framework.
                  I am a strong believer in feminism and equity and I think they are only possible if we support each
                  other and help each other to grow, which includes understanding what is hindering us. Fundamentally, I
                  was very pleased with having been able to offer a space where my mentee felt able to bring challenges
                  and obstacles they were experiencing. I then tried to gently challenge their own doubts and empower
                  them to recognise and own the value of their contributions to their workplace.
                  <span>Mentor from 2023 Programme</span></blockquote>
              </div>
            </div>
          </div>

<!--          <blockquote>As a feminist mentor within Rape Crisis, my experience supporting a fellow colleague has been-->
<!--            incredibly fulfilling. Providing guidance and support on her journey towards career progression within our-->
<!--            organisation has been both rewarding and enriching. Firstly, the training we as mentors were given was-->
<!--            great,-->
<!--            and provided us with many great tools to support the process. I deeply valued the space created by our-->
<!--            mentorship-->
<!--            program, as it allowed me to support a colleague in navigating her path within a feminist framework.-->
<!--            Through this process, I have had the opportunity to reflect on what development and growth within a feminist-->
<!--            organisation mean to me personally. The program has been exceptionally well-organized, providing us with-->
<!--            meaningful spaces for engaging and fulfilling conversations to take place. Overall, I am grateful for the-->
<!--            opportunity to mentor and be part of such a valuable initiative within our community.-->
<!--            <span>Heather Conboy, Therapeutic Team and Service Development Manager, KCRASAC</span></blockquote>-->

          <p>It is the mentor and mentee's decision on how long their relationship lasts, but typically mentoring will
            take up approximately one hour of your time per month, for six months.</p>

          <p>Due to the geographical spread of individuals within the network, it is anticipated that this programme
            will be conducted entirely virtually, however the frequency and location of your sessions will be agreed
            within the mentoring relationship to suit you both.</p>

          <p>In the interests of shared learning, there are no criterion for who can mentor, or who can receive
            mentoring. Regardless of your position within your centre, if you are hoping to learn or have something to
            share with another, mentoring is for you.</p>

          <p>Deadline for applications is Friday 19th April.</p>
          <p class="text-center">
            <b-button variant="contact" target="_blank" href="https://forms.gle/yW8ATHqhZr2rQWd89">
              <b-icon icon="pencil-square"></b-icon>
              Apply here
            </b-button>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import {tns} from 'tiny-slider';

export default {
  name: "About",
  title: "RCEW Feminist Mentoring Programme",
  data() {
    return {
      title: "About",
      slide: 0,
      sliding: null
    }
  },
  mounted: function () {
    tns({
      container: '#fm-quotes',
      "items": 1,
      "mouseDrag": true,
      "slideBy": "page",
      "swipeAngle": false,
      "speed": 400,
      // "autoplay": true,
      // "autoplayHoverPause": true,
      // "autoplayTimeout": 20000,
    });

  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>

<style scoped>
@import '../../node_modules/tiny-slider/dist/tiny-slider.css';


.about-text {
  margin: auto;
  padding-top: 30px;
}

.about-image {
  text-align: center;
}

.image-border {
  border-radius: 30px;
  display: block;

  background-color: rgba(0, 0, 0, 0.72);
  border: 2px solid #012A36;
  margin: auto;
}

.fm-col {
  background-color: rgba(1, 42, 54, 0.11);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}


a.fm-link {
  text-decoration: none !important;
  color: #832161 !important;
}

a.fm-link:hover {
  text-decoration: none !important;
  color: #c72c93 !important;
}

.page-header {
  background-color: rgb(1, 42, 54);
  width: fit-content;
  color: #e66eb2;
  margin: 30px 0px 30px 30px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  background-image: url('/static/images/about-header.jpg');
}


*, :after, :before {
  box-sizing: border-box
}

.pull-left {
  float: left
}

.pull-right {
  float: right
}

.clearfix:after, .clearfix:before {
  content: '';
  display: table
}

.clearfix:after {
  clear: both;
  display: block
}

.author-quote-wrap .author-quote,
.author-quote-wrap .author-photo:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.author-quote-wrap {
  color: #fff;
  width: 100%;
  overflow: hidden;
  max-width: 560px;
  min-height: 390px;
  margin: 50px auto 0;
  background: #832161;
  position: relative;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .22), 0 25px 55px 0 rgba(0, 0, 0, .21);
}

.author-quote-wrap .author-quote {
  display: none;
}

.author-quote-wrap .toggle-quote {
  display: none;
}

.author-quote-wrap .toggle-quote:checked + .author-quote {
  display: block;
}

.author-quote-wrap .author-quote a {
  color: inherit;
  text-decoration: none;
}

.author-quote-wrap .author-photo,
.author-quote-wrap .quote-content {
  height: 100%;
  position: relative;
}

.author-quote-wrap .author-photo {
  width: 140px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  quotes: '\201C''\201D''\2018''\2019';
}

.author-quote-wrap .author-photo:before {
  content: '';
  background: rgb(215, 105, 166);
}

.author-quote-wrap .author-photo:after {
  top: 15px;
  left: 60px;
  font-size: 72px;
  line-height: 72px;
  position: absolute;
  font-family: serif;
  content: open-quote;
}


.author-quote-wrap .quote-content {
  padding: 15px;
  width: calc(560px - 140px);
}

.quote-content .quote-like {
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 15px;
  display: inline-block;
  background: rgba(0, 0, 0, .2);
}

.quote-content .quote-like .love {
  width: 18px;
  height: 10px;
  position: relative;
  display: inline-block;
}

.quote-content .quote-like .love:before,
.quote-content .quote-like .love:after {
  top: 0;
  left: 7px;
  content: '';
  width: 7px;
  height: 12px;
  background: #fff;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  border-radius: 20px 20px 0 0;
}

.quote-content .quote-like .love:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}


.quote-content .quote-text {
  font-size: 30px;
  font-weight: 900;
  line-height: 55px;
}

.quote-content .quote-author {
  bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
}

.quote-content .quote-author:before {
  content: '- ';
}

.container-xl {
  font-size: 20px;
}

.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom: 5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0.1), rgba(2, 43, 54, 0.1)), url('/static/images/rcew-header.jpg') no-repeat right;
  background-size: cover;
}

.card-header {
  background-color: #d568a57d;
}

.btn-contact {
  background-color: #840a4dc2;
  color: white;
  margin-top: 20px;
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: 0.5rem;
  font-size: 20px;
}


.btn-contact:hover {
  background-color: rgba(192, 16, 113, 0.76);
  color: white;
}


</style>
