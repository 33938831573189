import Vue from 'vue'
import Router from 'vue-router'
import VueMeta from 'vue-meta'

import { store } from "@/store"
import Home from "@/components/Home";
import MenteeHub from "@/components/MenteeHub";
import Admin from "@/components/Admin";
import ProgrammeList from "@/components/admin/ProgrammeList";
import ManageProgramme from "@/components/admin/ManageProgramme";
// eslint-disable-next-line no-unused-vars
import ShowUsers from "@/components/admin/ShowUsers";
import TestData from "@/components/admin/TestData";
import DocumentList from "@/components/admin/DocumentList";
import ManageDocument from "@/components/admin/ManageDocument";
import Forum from "@/components/forum/Forum";
import Post from "@/components/forum/Post";
import Thread from "@/components/forum/Thread";
import Profile from "@/components/Profile";
import MentorHub from "@/components/MentorHub";
import Journal from "@/components/Journal.vue";
import {checkAuthenticated} from "@/auth";
// import Blog from "@/components/Blog.vue";
import About from "@/components/About.vue";
import Contact from "@/components/Contact.vue";
import RCEW from "@/components/RCEW.vue";
import PageNotFound from "@/components/PageNotFound.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import RCEWTandC from "@/components/RCEWTandC.vue";
import ContactRequests from "@/components/admin/ContactRequests.vue";
import Login from "@/components/Login.vue";
import BulkLoad from "@/components/admin/BulkLoad.vue";
import Activate from "@/components/Activate.vue";
import codeofconduct from "@/components/forum/codeofconduct.vue";
import ForgotPassword from "@/components/ForgotPassword.vue";
import Reset from "@/components/Reset.vue";
import Equalities from "@/components/survey/Equalities.vue";
import TrainingSurvey from "@/components/survey/TrainingSurvey.vue";
import BeforeSurvey from "@/components/survey/BeforeSurvey.vue";
import ManageSurvey from "@/components/admin/ManageSurvey.vue";
import NotificationList from "@/components/admin/NotificationList.vue";
import Feedback from "@/components/Feedback.vue";
import Consulting from "@/components/Consulting.vue";
// import WhatsTheDifferenceBetweenMentoringAndFeministMentoring
    //     from "@/components/blog/whats-the-difference-between-mentoring-and-feminist-mentoring.vue";

Vue.use(Router)
Vue.use(VueMeta)

export const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        // {
        //     path: '/blog',
        //     name: 'blog',
        //     component: Blog
        // },
        // {
        //     path: '/blog/whats-the-difference-between-mentoring-and-feminist-mentoring',
        //     name: 'whats-the-difference-between-mentoring-and-feminist-mentoring',
        //     component: WhatsTheDifferenceBetweenMentoringAndFeministMentoring
        // },
        {
            path: '/consulting',
            name: 'consulting',
            component: Consulting
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/forgotpassword',
            name: 'forgotpassword',
            component: ForgotPassword
        },
        {
            path: '/feminist-mentoring',
            name: 'About',
            component: About
        },
        {
            path: '/rcew',
            name: 'RCEW',
            component: RCEW
        },
        {
            path: '/rcew-terms-and-conditions',
            name: 'RCEWTandC',
            component: RCEWTandC
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/feedback',
            name: 'Feedback',
            component: Feedback
        },
        {
            path: '/menteehub',
            name: 'MenteeHub',
            component: MenteeHub
        },
        {
            path: '/journal',
            name: 'Journal',
            component: Journal
        },
        {
            path: '/mentorhub',
            name: 'MentorHub',
            component: MentorHub
        },
        {
            path: '/profile',
            name: 'Profile',
            component: Profile
        },
        {
            path: '/forum',
            name: 'Forum',
            component: Forum
        },
        {
            path: '/codeofconduct',
            name: 'codeofconduct',
            component: codeofconduct
        },
        {
            path: '/post',
            name: 'Post',
            component: Post
        },
        {
            path: '/thread',
            name: 'Thread',
            component: Thread
        },
        {
            path: '/privacypolicy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy
        },
        {
            path: '/equalitiessurvey',
            name: 'Equalities',
            component: Equalities
        },
        {
            path: '/beforesurvey',
            name: 'BeforeSurvey',
            component: BeforeSurvey
        },
        {
            path: '/trainingsurvey',
            name: 'TrainingSurvey',
            component: TrainingSurvey
        },
        {
            path: '/admin',
            name: 'Admin',
            component: Admin
        },
        {
            path: '/admin/programmes',
            name: 'ProgramList',
            component: ProgrammeList
        },
        {
            path: '/admin/managedocument',
            name: 'ManageDocument',
            component: ManageDocument
        },
        {
            path: '/admin/documentlist',
            name: 'DocumentList',
            component: DocumentList
        },
        {
            path: '/admin/notificationlist',
            name: 'NotificationList',
            component: NotificationList
        },
        {
            path: '/admin/bulkload',
            name: 'Bulk Load',
            component: BulkLoad
        },
        {
            path: '/admin/manageprogramme',
            name: 'ManageProgramme',
            component: ManageProgramme
        },
        {
            path: '/admin/managesurvey',
            name: 'ManageSurvey',
            component: ManageSurvey
        },
        {
            path: '/admin/contactrequests',
            name: 'ContactRequests',
            component: ContactRequests
        },
        // {
        //     path: '/register',
        //     name: 'Register',
        //     component: Register
        // },
        {
            path: '/activate/*',
            name: 'Activate',
            component: Activate
        },
        {
            path: '/reset/*',
            name: 'Reset',
            component: Reset
        },
        {
            path: '/showusers',
            name: 'ShowUsers',
            component: ShowUsers
        },
        {
            path: '/testdata',
            name: 'TestData',
            component: TestData
        },
        {
            path: '*',
            component: PageNotFound
        }

    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    checkAuthenticated();
    const publicPages = ['/', '/home', '/register', '/about', '/login', '/blog', '/contact', '/rcew', '/privacypolicy', '/feminist-mentoring', '/rcew-terms-and-conditions', '/forgotpassword', '/consulting'];
    const authRequired = !publicPages.includes(to.path);
    const privatePages = ['/admin', '/Admin'];
    const privAuthRequired = privatePages.includes(to.path);
    const loggedIn = store.state.isAuthenticated;
    const isAdmin  = store.state.isAdmin;
    const targetPath = window.location.pathname;

    if ((authRequired && !loggedIn) ) {
        if (to.path.startsWith('/activate/') || to.path.startsWith('/reset/') || to.path.startsWith('/blog/') ) {
            next();
        } else {
            return next({ name: 'login', query: { from: targetPath } })
        }
        // next({ name: 'Login', query: { from: loginpath } });
    } else if (privAuthRequired && !isAdmin) {
        return next({ name: 'login', query: { from: targetPath } })
    }
    next();
})

export default router;