
let env_baseUrl;

if (process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-unused-vars
    env_baseUrl = 'http://localhost:8000/'
    // env_baseUrl = 'http://192.168.0.121:8100/'
}
else if(process.env.NODE_ENV === "test") {
    // eslint-disable-next-line no-unused-vars
    env_baseUrl = 'https://test-api.bekiosborne.com/'
}
else if(process.env.NODE_ENV === "production") {
    // eslint-disable-next-line no-unused-vars
    env_baseUrl = 'https://api.bekiosborne.com/'
}

export const envbaseUrl = env_baseUrl;