<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <p class="fm-header-text">Forum Code of Conduct</p>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4">
        <b-col cols="12" class="about-text">


          <div class="entry-content">


            <p>We are delighted to offer an online forum for mentors and mentees to seek peer support and share
              resources. We aim to hold the space on the forum to reflect our feminist and inclusive values as an
              extension of the programme.</p>
            <p>To ensure that the space is upheld in the same standards as the wider programme, we ask you to follow our
              code of conduct. <br><strong>If this code is not observed, we reserve the right to remove posts and, in
                serious
                circumstances, remove you from the platform and the programme.</strong></p>
            <p>Please think before you post, be considerate in the same way that you would be in real life and uphold
              values and your commitments to confidentiality with your mentee.</p>
            <p>It may be wise to;</p>
            <ul>
              <li>Read through the information on the forum and other participant’s comments before posting your
                comment
              </li>
              <li>Respecting other people’s opinions, even if you don’t agree with them, and be constructive when
                offering an alternative view or perspective
              </li>
              <li>Consider adding a reference to any opinions you share, so others can undertake further research and
                come to their own conclusions if they’d like to
              </li>
              <li>Once you’ve left your comment, keep an eye on the forum to see what other people have to say</li>
            </ul>

            <p>We will not tolerate;</p>
            <ul>
              <li>Discriminatory, inappropriate language or hate speech</li>
              <li>Personal attacks, threats or defamatory comments</li>
              <li>Hate, aggression or abuse</li>
              <li>Libellous or misleading content</li>
              <li>Religious intolerance of any kind</li>
              <li>Offensive or distressing content or graphic imagery</li>
              <li>Trolling</li>
              <li>Spam or sales posts</li>
            </ul>
            <p>We will check the forum sporadically when we’re working. If you have any concerns about a post please use the "Report this post" button. If you need to contact anyone for support or
              have an urgent enquiry, please contact us via the contact form or email address supplied to you during registration.</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>

export default {
  name: "Feminist Mentoring - Forum Code of Conduct",
  title: "Feminist Mentoring - Forum Code of Conduct",
  data() {
    return {
      title: "Feminist Mentoring - Forum Code of Conduct"
    }
  },
  mounted: function () {

  },
  methods: {}
}
</script>

<style scoped>

.about-text {
  margin: auto;
  padding-top: 30px;
}

.about-image {
  text-align: center;
}

.image-border {
  border-radius: 30px;
  display: block;

  background-color: rgba(0, 0, 0, 0.72);
  border: 2px solid #012A36;
  margin: auto;
}

.fm-col {
  background-color: rgba(1, 42, 54, 0.11);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.blockquote {
  background-color: rgba(222, 107, 172, 0.24);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}

a.fm-link {
  text-decoration: none !important;
  color: #c5198a !important;
}

a.fm-link:hover {
  text-decoration: none !important;
  color: #ff1bb3 !important;
}

.page-header {
  background-color: rgb(1, 42, 54);
  width: fit-content;
  color: #e66eb2;
  margin: 30px 0px 30px 30px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  background-image: url('/static/images/about-header.jpg');
}


*, :after, :before {
  box-sizing: border-box
}


.container-xl {
  font-size: 20px;
}

.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom: 5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0.1), rgba(2, 43, 54, 0.1)), url('/static/images/rcew-header.jpg') no-repeat right;
  background-size: cover;
}

</style>
