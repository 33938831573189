<template>
  <b-container fluid="xl" class="w-100 mw-100 p-2 m-0">
<br>
    <b-row>
      <b-col v-if="this.mentor" class="d-flex justify-content-center">
        <b-card
            style="max-width: 90%; min-width: 90%;"
            img-src="/static/images/mentor-header.jpg"
            img-top>
          <template #header>
            <h4 class="mb-0">
              <b-icon icon="book" style="width: 20px; height: 20px;"></b-icon>
              {{ mentor_profile.programme_name }} - Mentor Hub
              <span class="hub-page-tour"><b-button variant="journal" size="sm" @click="startTour"><b-icon icon="question-circle" style="width: 15px; height: 15px;" ></b-icon> Page Tour</b-button></span>
            </h4>
          </template>
          <b-card-text>
            <div>
              <div>
                <b-card-group deck>

                  <b-card
                      border-variant="journey"
                      header="About You"
                      header-bg-variant="journey"
                      header-border-variant="journey"
                      header-text-variant="white"
                      align="center"
                      class="m-2"
                  >
                    <b-card-text>
                      <b-list-group>
                        <b-list-group-item href="#" class="flex-column align-items-start" disabled>
                          <p class="mb-1">
                            <b>Motivation </b>{{ mentor_profile.motivation}}
                          </p>
                        </b-list-group-item>
                      </b-list-group>
                    </b-card-text>
                  </b-card>
                  <b-card
                      border-variant="journal"
                      header="Journal"
                      header-bg-variant="journal"
                      header-border-variant="journal"
                      header-text-variant="white"
                      align="center"
                      class="m-2"
                  >
                    <b-card-text>
                      This is a space for you to reflect and log notes as your journey progresses<br><br>
                      <b-button variant="journal" :to="{ name: 'Journal' }"><b-icon icon="journal"></b-icon> Open your Journal</b-button>
                    </b-card-text>
                  </b-card>

                </b-card-group>
              </div>
              <div>
                <b-card-group deck>


                  <b-card
                      border-variant="forum"
                      header="Latest Forum Activity"
                      header-bg-variant="forum"
                      header-border-variant="forum"
                      header-text-variant="white"
                      align="center"
                      class="m-2"
                  >
                    <b-card-text>
                      <b-row v-if="this.threads.length === 0 && this.loaded">
                        <b-col cols="12">
                          <p>No posts yet</p>
                        </b-col>
                      </b-row>
                      <b-row class="thread" v-for="(value, id) in this.threads" :key="id">
                        <b-col cols="8">
                          <p class="thread-title"><b-link :to="{ name: 'Thread', params: { threadId: value.id, threadTitle: value.title } }">{{ value.title }}</b-link></p>
                          <p class="posted-by">Posted by {{ value.author }} | {{ value.created_at }}</p>
                        </b-col>
                        <b-col cols="1">{{ value.latest_post.replies }}</b-col>
                        <b-col cols="3">
                          <p class="last-post-by">By {{ value.latest_post.latest_post_author }} </p>
                          <p class="last-post-date">{{ value.latest_post.latest_post_date}}</p>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>

                  <b-card
                      border-variant="documents"
                      header="Resources"
                      header-bg-variant="documents"
                      header-text-variant="white"
                      align="center"
                      class="m-2"
                  >
                    <b-card-text>
                      <ul id="document-list">
                        <li><span class="caret">Resources</span>
                          <ul class="nested" id="resource-docs">
                          </ul>
                        </li>
                        <li><span class="caret">Training</span>
                          <ul class="nested" id="training-docs">
                          </ul>
                        </li>
                      </ul>
                    </b-card-text>
                  </b-card>

                </b-card-group>
              </div>
            </div>

          </b-card-text>

        </b-card>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import api from "../api.js";
// import {store} from "@/store";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default {
  name: "MentorHub",
  title: "Feminist Mentoring - Mentor Hub",
  data() {
    return {
      threads: [],
      loaded: false,
      mentor: false,
      mentee: false,
      driverObj: null,
      mentor_profile: {
        programme_name: ''
      },
      programme_id: ''
    }
  },
  mounted: function () {
    this.getProfile();
    this.getDocuments();
    this.getThreads();

    this.driverObj = driver({
      showProgress: true,
      showButtons: ['next', 'previous'],
      steps: [
        { popover: { title: 'Welcome', description: 'This is the Mentor Hub, a location for you to journal your progress and access training material', side: "left", align: 'start' }},
        { element: '.border-journey', popover: { title: 'About You', description: 'A reminder of your motivation for mentoring', side: "bottom", align: 'start' }},
        { element: '.border-journal', popover: { title: 'Journal', description: 'Keep a journal of your thoughts and reflections during your Mentoring journey', side: "bottom", align: 'start' }},
        { element: '.border-forum', popover: { title: 'Forum', description: 'Connect with other Mentors on your programme to share experiences and ask for support', side: "left", align: 'start' }},
        { element: '.border-documents', popover: { title: 'Resources', description: 'All important Feminist Mentoring documents, including training recordings, are available here', side: "top", align: 'start' }},
        { element: '.notification-nav', popover: { title: 'Notifications', description: 'At times during the programme you may be asked to complete questionnaires or take other actions to help track your progress', side: "top", align: 'start' }},
        { popover: { title: "You're good to go!", description: "We'll see you at the training!", side: "left", align: 'start' }},
      ]
    });



  },
  methods: {
    getThreads() {
      api.get("/threads/")
          .then((response) => {
            this.loaded = true;
            this.threads = response.data.mentor_threads.slice(0, 2);
          })
    },
    startTour() {
      this.driverObj.drive();
    },

    getProfile() {
      api.get("/profile/")
          .then((response) => {
            this.mentee = response.data.profile.baseuserprofile.mentee;
            this.mentor = response.data.profile.baseuserprofile.mentor;
            this.mentor_profile.programme_name = response.data.profile.progname;
            this.mentor_profile.bio = response.data.profile.baseuserprofile.bio;
            this.mentor_profile.motivation = response.data.mentorprofile.motivation;
            this.programme_id = response.data.profile.programme_id
          })
    },
    getDocuments() {
      api.get("/documents/")
          .then((response) => {
            setTimeout(() => {
              this.createDocumentTree(response.data);
            }, "500");
          })
    },
    createDocumentTree(documents) {
      let toggler = document.getElementsByClassName("caret");
      let i;

      for (i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function() {
          this.parentElement.querySelector(".nested").classList.toggle("active");
          this.classList.toggle("caret-down");
        });
        let docCount = 0;

        if (toggler[i].innerHTML === "Resources") {
          if (documents.length === 0) {
            console.log("INFO: No Resources");
          }
          for (let i = 0; i < documents.length; i++) {
            if (documents[i].category === "resource") {
              docCount = docCount + 1
              let list = document.getElementById('resource-docs')
              let li = document.createElement("li");
              li.classList.add("document-link")
              li.appendChild(document.createTextNode(documents[i].name));
              li.addEventListener('click', function() { window.open(documents[i].document,"_blank"); } )
              list.appendChild(li)
            }
          }
          if(docCount === 0){
            let list = document.getElementById('resource-docs')
            let li = document.createElement("li");
            li.classList.add("no-document-placeholder")
            li.appendChild(document.createTextNode("No documents available at the moment"));
            list.appendChild(li)
          }
          docCount = 0
        }
        else if (toggler[i].innerHTML === "Training") {
          for (let i = 0; i < documents.length; i++) {
            if (documents[i].category === "training") {
              docCount = docCount + 1
              let list = document.getElementById('training-docs')
              let li = document.createElement("li");
              li.classList.add("document-link")
              li.appendChild(document.createTextNode(documents[i].name));
              li.addEventListener('click', function() { window.open(documents[i].document,"_blank"); } )
              list.appendChild(li)
            }
          }
          if(docCount === 0){
            let list = document.getElementById('training-docs')
            let li = document.createElement("li");
            li.classList.add("no-document-placeholder")
            li.appendChild(document.createTextNode("No documents available at the moment"));
            list.appendChild(li)
          }

        }

      }
    },
  }
}
</script>

<style scoped>

.card img {
  opacity: 0.7;
}

.card-deck {
  justify-content: flex-start;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-deck .card {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  margin-right: 15px;
  margin-bottom: 0;
  margin-left: 15px;
  min-width: 170px;
}

/* Remove default bullets */
ul, #document-list {
  list-style-type: none;
}

/* Remove margins and padding from the parent ul */
#document-list {
  margin: 0;
  padding: 0;
  text-align:left;
}

/* Style the caret/arrow */
.caret {
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

/* Create the caret/arrow with a unicode, and style it */
.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

/* Rotate the caret/arrow icon when clicked on (using JavaScript) */
.caret-down::before {
  transform: rotate(90deg);
}

/* Hide the nested list */
.nested {
  display: none;
}

/* Show the nested list when the user clicks on the caret/arrow (with JavaScript) */
.active {
  display: block;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: #52050a1f;
}

.bg-notifications {
  --bs-bg-opacity: 1;
  background-color: #74E66E !important;
}

.border-notifications {
  --bs-border-opacity: 1;
  border-color: #74E66E !important;
}

.bg-journey {
  --bs-bg-opacity: 1;
  background-color: #A67992 !important;
}

.border-journey {
  --bs-border-opacity: 1;
  border-color: #A67992 !important;
}

.bg-journal {
  --bs-bg-opacity: 1;
  background-color: #a67979 !important;
}

.border-journal {
  --bs-border-opacity: 1;
  border-color: #a67979 !important;
}

.bg-forum {
  --bs-bg-opacity: 1;
  background-color: #779176 !important;
}

.border-forum {
  --bs-border-opacity: 1;
  border-color: #779176 !important;
}

.bg-documents {
  --bs-bg-opacity: 1;
  background-color: #66545E !important;
}

.border-documents {
  --bs-border-opacity: 1;
  border-color: #66545E !important;
}

.posted-by {
  margin-bottom: 0px;
  font-size: 10px;
}

.last-post-date {
  margin-bottom: 0px;
  font-size: 14px;
}

.last-post-by {
  margin-bottom: 0px;
  font-size: 12px;
}

.thread-title {
  margin-bottom: 1px;
}

.thread-title a {
  text-decoration: none;
  font-weight: bold;
  color: #832161;
}

.thread-title a:hover {
  color: #ff00a7;
}

.thread {
  background-color: #d2d2d2;
  margin-bottom: 2px;
}

.thread:hover {
  background-color: #b9789d8c;
}

.thread-headers {
  background-color: #e56db1c9;
  padding-top: 3px;
  padding-bottom: 3px;
}

.thread-headers :nth-child(2) {
  text-align: center;
}

.thread div:nth-child(2) {
  text-align: center;
}

.btn-journal {
  background-color: #840a4dc2;
  color: white;
}


.btn-journal:hover {
  background-color: rgba(192, 16, 113, 0.76);
  color: white;
}

</style>