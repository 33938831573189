<template>
  <b-container>
    <br>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'ShowUsers' }" class="fm-link">Users</b-breadcrumb-item>
        <b-breadcrumb-item active>Bulk Load Users</b-breadcrumb-item>
      </b-breadcrumb>

      <b-form @submit="onSubmit">
        <b-row class="pb-3">
          <b-form-file
              v-model="file1"
              :state="Boolean(file1)"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-row>
        <b-row>
          <b-button class="mr-2" type="submit" variant="primary">Save</b-button>
        </b-row>
      </b-form>
      </div>

  </b-container>

</template>

<script>
import api from '../../api.js'

export default {
  // props: ['id'],
  data() {
    return {
      file1: null,
      category_options: [
        { value: null, text: 'Please select an option' , disabled: true  },
        { value: 'agreement', text: 'Agreement' },
        { value: 'training', text: 'Training' },
        { value: 'resource', text: 'Resource' }
      ],

      validationError: false,
      validationOutput: ""
    }
  },

  methods: {

    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('file', this.file1);
      api.post('bulkload/', formData, )
          .then((response) => {
            console.log("success!" + response.data);
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }
}
</script>
<style scoped>
.custom-file-input {
  z-index: 2;
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file, .custom-file-input {
  position: relative;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
}

</style>