import Vue from "vue";
import jwt_decode from "jwt-decode";
import {store} from "@/store";
import router from "@/router";


const checkAuthenticated = () => {
    const loggedIn = store.state.isAuthenticated;
    const token = Vue.$cookies.get('access');

    if (loggedIn) {
        return
    }
    else if (token === null ||  token === undefined || token === "null" || token === "undefined") {
        return
    }
    const decoded = jwt_decode(token)
    let currentTime = Math.trunc(Date.now()/1000)
    if (decoded.exp > currentTime) {
        store.commit("setAuthUser",
            {authUser: decoded.name, isAdmin: decoded.isAdmin,
                isMentor: decoded.isMentor, isMentee: decoded.isMentee,
                isAuthenticated: true,
                access: token, refresh: ""})

    }
    else {
        logout()
    }
        // store.commit("setAuthUser",
        //     {authUser: null, isAuthenticated: false, isAdmin: false, isMentee: false, isMentor: false, access: null, refresh: null})
        // router.push({path: '/'})    }
}

const logout = () => {
    store.commit("setAuthUser",
        {authUser: null, isAuthenticated: false, isAdmin: false, isMentee: false, isMentor: false, access: null, refresh: null})
    router.push({path: '/'})
    Vue.$cookies.remove("access")
    Vue.$cookies.remove("refresh")
    Vue.$cookies.remove("csrftoken")
}

export { checkAuthenticated, logout }