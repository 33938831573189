<template>
  <b-container>
    <div v-if="this.mentor">
      <h3 class="forum-header">Mentor Forum</h3>
      <b-button-toolbar aria-label="New post button">
        <b-button-group class="mx-1">
          <b-button v-b-modal.new-thread-modal variant="fm" class="new-post-button" @click="posttype = 'mentor'">New Post</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="fm"  to="codeofconduct" target="_blank" class="code-of-conduct-button"><b-icon icon="book" style="width: 15px; height: 15px;" ></b-icon> Code of Conduct</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="fm" size="sm" @click="driverObj.drive()"><b-icon icon="question-circle" style="width: 15px; height: 15px;" ></b-icon> Page Tour</b-button>
        </b-button-group>
      </b-button-toolbar>

    <div class="threads mt-4">
      <b-row class="thread-headers" >
        <b-col cols="7">Threads</b-col>
        <b-col cols="2">Replies</b-col>
        <b-col cols="3">Last Post</b-col>
      </b-row>
      <b-row class="thread p-4" v-if="!this.loaded">
        <b-row class="justify-content-center">
          <b-spinner style="width: 5rem; height: 5rem; color: #e56db2;"></b-spinner>
        </b-row>
      </b-row>
      <b-row class="thread" v-for="(value, id) in this.mentor_threads" :key="id">
        <b-col cols="7">
          <p class="thread-title"><b-link :to="{ name: 'Thread', params: { threadId: value.id, threadTitle: value.title } }">{{ value.title }}</b-link></p>
        <p class="posted-by">Posted by {{ value.author }} | {{ value.created_at }}</p>
        </b-col>
        <b-col cols="2">{{ value.latest_post.replies }}</b-col>
        <b-col cols="3">
          <p class="last-post-by">By {{ value.latest_post.latest_post_author }} </p>
          <p class="last-post-date">{{ value.latest_post.latest_post_date}}</p>
        </b-col>
      </b-row>
      <b-row class="thread" v-if="this.mentor_threads.length === 0 && this.loaded">
        <b-col cols="8">
          <p class="thread-title">No posts yet, get the conversation started by hitting the "New Post" button above</p>
        </b-col>
        <b-col cols="1"></b-col>
        <b-col cols="3">
        </b-col>
      </b-row>
    </div>

    </div>
<br>
    <div v-if="this.mentee">
      <h3 class="forum-header">Mentee Forum</h3>
      <b-button-toolbar aria-label="New post button">
        <b-button-group class="mx-1">
          <b-button v-b-modal.new-thread-modal variant="fm" class="new-post-button" @click="posttype = 'mentee'">New Post</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="fm"  to="codeofconduct" target="_blank" class="code-of-conduct-button"><b-icon icon="book" style="width: 15px; height: 15px;" ></b-icon> Code of Conduct</b-button>
        </b-button-group>
        <b-button-group class="mx-1">
          <b-button variant="fm" size="sm" @click="driverObj.drive()"><b-icon icon="question-circle" style="width: 15px; height: 15px;" ></b-icon> Page Tour</b-button>
        </b-button-group>
      </b-button-toolbar>

      <div class="threads mt-4">
        <b-row class="thread-headers" >
          <b-col cols="7">Threads</b-col>
          <b-col cols="2">Replies</b-col>
          <b-col cols="3">Last Post</b-col>
        </b-row>
        <b-row class="thread p-4" v-if="!this.loaded">
          <b-row class="justify-content-center">
            <b-spinner style="width: 5rem; height: 5rem; color: #e56db2;"></b-spinner>
          </b-row>
        </b-row>
        <b-row class="thread" v-for="(value, id) in this.mentee_threads" :key="id">
          <b-col cols="7">
            <p class="thread-title"><b-link :to="{ name: 'Thread', params: { threadId: value.id, threadTitle: value.title } }">{{ value.title }}</b-link></p>
            <p class="posted-by">Posted by {{ value.author }} | {{ value.created_at }}</p>
          </b-col>
          <b-col cols="2">{{ value.latest_post.replies }}</b-col>
          <b-col cols="3">
            <p class="last-post-by">By {{ value.latest_post.latest_post_author }} </p>
            <p class="last-post-date">{{ value.latest_post.latest_post_date}}</p>
          </b-col>
        </b-row>
        <b-row class="thread" v-if="this.mentee_threads.length === 0 && this.loaded">
          <b-col cols="8">
            <p class="thread-title">No posts yet, get the conversation started by hitting the "New Post" button above</p>
          </b-col>
          <b-col cols="1"></b-col>
          <b-col cols="3">
          </b-col>
        </b-row>
      </div>
    </div>

    <b-modal size="lg" id="new-thread-modal" title="Login" v-model="modalShow">
      <template #modal-header="">
        <h5>New Thread</h5>
      </template>

      <b-form @submit="onSubmit">
        <b-row class="pb-3">
          <b-col cols="8">
            <b-form-group id="input-group-13" label="Subject" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="thread.title"
                  type="text"
                  required
                  placeholder="Add a descriptive title for your post"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group id="textarea-group" label="Content" label-for="textarea">
              <b-form-textarea
                  id="textarea"
                  v-model="post.content"
                  placeholder="Enter post contents"
                  rows="3"
                  max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <div id="post-editor"></div>
        </b-row>
        <!--      <b-row class="pb-3 float-start mt-4">-->
        <!--        <b-col>-->
        <!--          <b-button class="mr-2" type="submit" variant="primary">Save</b-button>-->
        <!--        </b-col>-->
        <!--      </b-row>-->
      </b-form><br>
      <p>Do not share personal information about yourself or others.</p>
      <p>Ensure you keep our <b-link class="fm-link" target="_blank" to="codeofconduct">Forum Code of Conduct</b-link> in mind each time you post.</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="md" variant="success" @click="onSubmit">
          Post
        </b-button>
        <b-button size="md" variant="danger" @click="cancel()">
          Cancel
        </b-button>
      </template>
      <br>
    </b-modal>


  </b-container>
</template>

<script>
import api from "@/api";
import {driver} from "driver.js";
// import Squire from 'squire-rte';

export default {
  name: "Forum",
  title: "Feminist Mentoring - Forum",
  data() {
    return {
      thread: {
        title: '',
      },
      post: {
        content: '',
      },
      posttype: null,
      editor: null,
      post_editor: null,
      driverObj: null,
      validationError: false,
      validationOutput: "",
      mentor: false,
      mentee: false,
      programme_id: '',
      programme_name: '',
      modalShow: false,
      loaded: false,
      items: [],
      mentor_threads: [],
      mentee_threads: [],
      fields: ['category', 'name', 'description', 'updated_at', 'document', 'default_document', 'mentor_document', 'mentee_document', 'manage', 'delete']
    }
  },
  mounted: function () {
    this.getProfile();
    this.getThreads();
    this.post_editor = document.getElementById('post-editor')
    this.driverObj = driver({
      showProgress: true,
      showButtons: ['next', 'previous'],
      steps: [
        { popover: { title: 'Forum', description: 'This is the Forum where you can ask questions and share experiences with your peers', side: "left", align: 'start' }},
        { element: '.threads', popover: { title: 'Threads', description: 'All open threads are shown here', side: "bottom", align: 'start' }},
        { element: '.threads', popover: { title: 'Threads', description: 'Mentors can only see posts from other mentors, and mentees can only see posts from other mentees', side: "bottom", align: 'start' }},
        { element: '.new-post-button', popover: { title: 'New Post', description: 'Click this button to make a new post, try introducing yourself or sharing an experience!', side: "left", align: 'start' }},
        { element: '.code-of-conduct-button', popover: { title: 'Code of Conduct', description: 'Make sure to review our Forum Code of Conduct before you start posting', side: "left", align: 'start' }},
      ]
    });
    // this.editor = new Squire(this.post_editor);

  },
  methods: {
    getProfile() {
      api.get("/profile/")
          .then((response) => {
            this.mentee = response.data.profile.baseuserprofile.mentee;
            this.mentor = response.data.profile.baseuserprofile.mentor;
            this.programme_name = response.data.profile.progname;
            this.programme_id = response.data.profile.programme_id
          })
    },
    getThreads() {
      api.get("/threads/")
          .then((response) => {
            this.loaded = true;
            console.log(response.data)
            this.mentee_threads = response.data.mentee_threads;
            this.mentor_threads = response.data.mentor_threads;
          })
    },
    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('forum', this.$route.params.id);
      formData.append('programme_id', this.programme_id);
      formData.append('title', this.thread.title);
      formData.append('post', this.post.content);
      formData.append('posttype', this.posttype);

      api.post("newthread/", formData)
          .then(() => {
            this.modalShow = false;
            this.thread.title = '';
            this.post.content = '';
            this.posttype = null;
            this.getThreads();
          })

    }
  }
}
</script>

<style scoped>

.posted-by {
  margin-bottom: 0px;
  font-size: 10px;
}

.last-post-date {
  margin-bottom: 0px;
  font-size: 14px;
}

.last-post-by {
  margin-bottom: 0px;
  font-size: 12px;
}

.thread-title {
  margin-bottom: 1px;
}

.thread-title a {
  text-decoration: none;
  font-weight: bold;
  color: #832161;
}

.thread-title a:hover {
  color: #ff00a7;
}

.thread {
  background-color: #d2d2d2;
  margin-bottom: 2px;
}

.thread:hover {
  background-color: #b9789d8c;
}

.thread-headers {
  background-color: #e56db1c9;
  padding-top: 3px;
  padding-bottom: 3px;
}

.thread-headers :nth-child(2) {
  text-align: center;
}

.thread div:nth-child(2) {
  text-align: center;
}

.forum-header {
  padding-top: 10px;
  padding-bottom: 5px;
}


</style>