<template>
  <div class="timeline__event  animated fadeInUp delay-3s timeline__event--type1 " v-bind:class="type">
    <div class="timeline__event__icon ">
      <i class="lni-cake"></i>
      <div class="timeline__event__date">
        {{ journal_date }}
        {{ journal_time }}
      </div>
    </div>
    <div class="timeline__event__content ">
      <div class="timeline__event__title" v-if=" type === 'mentormeeting'">
        Mentor Meeting
      </div>
      <div class="timeline__event__title" v-else-if=" type === 'reflection'">
        Reflection
      </div>
      <div class="timeline__event__title" v-else-if=" type === 'notes'">
        Notes
      </div>
      <div class="timeline__event__description">
        <p>{{ content }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Journal Entry",
  props: ['date', 'type', 'content'],
  data() {
    return {
      journal_date : null,
      journal_time : null
    }
  },
  mounted() {
    console.log(this.date)
    let date_obj = new Date(this.date);
    this.journal_date = date_obj.getDate() + '/' + (date_obj.getMonth() + 1) + '/' + date_obj.getFullYear()
    this.journal_time = date_obj.getHours() + ':' + ((date_obj.getMinutes() < 10 ? '0' : '') + date_obj.getMinutes())
    console.log(this.journal_time)

  }
}


</script>

<style scoped>
.timeline {
  display: flex;
  flex-direction: column;
  width: 80vw;
}

.timeline__event {
  background: #fff;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  margin: 20px 0;
  border-radius: 8px;
  box-shadow: 0 30px 60px -12px rgba(50, 50, 93, 0.25), 0 18px 36px -18px rgba(0, 0, 0, 0.3), 0 -12px 36px -8px rgba(0, 0, 0, 0.025);
}

.timeline__event__title {
  font-size: 1.5rem;
  line-height: 1.4;
  //text-transform: uppercase;
  font-weight: 400;
  color: #9251ac;
  letter-spacing: 1.5px;
  font-family: "DuniaKita";
}

.timeline__event__content {
  padding: 5px;
  margin-left: 5px;
}

.timeline__event__date {
  color: #f6a4ec;
  font-size: 1.2rem;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 5px;
  margin-left: 5px;
}

.timeline__event__icon {
  border-radius: 8px 0 0 8px;
  background: #9251ac;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 20%;
  font-size: 2rem;
  color: #9251ac;
  padding: 5px;
}

.timeline__event__icon i {
  position: absolute;
  top: 50%;
  left: -65px;
  font-size: 2.5rem;
  transform: translateY(-50%);
}

.timeline__event__description {
  flex-basis: 60%;
}

.timeline__event:after {
  content: "";
  width: 2px;
  height: 100%;
  background: #9251ac;
  position: absolute;
  top: 52%;
  left: -3.5rem;
  z-index: -1;
}

.timeline__event--type2:after {
  background: #555ac0;
}

.timeline__event--type2 .timeline__event__date {
  color: #87bbfe;
}

.timeline__event--type2 .timeline__event__icon {
  background: #555ac0;
  color: #555ac0;
}

.timeline__event--type2 .timeline__event__title {
  color: #555ac0;
}


.mentormeeting:after {
  background: #555ac0;
}

.mentormeeting .timeline__event__date {
  color: #87bbfe;
}

.mentormeeting .timeline__event__icon {
  background: #555ac0;
  color: #555ac0;
}

.mentormeeting .timeline__event__title {
  color: #555ac0;
}


.timeline__event--type3:before {
  background: #aff1b6;
  border-color: #24b47e;
}

.timeline__event--type3:after {
  background: #24b47e;
}

.timeline__event--type3 .timeline__event__date {
  color: #aff1b6;
}

.timeline__event--type3 .timeline__event__icon {
  background: #24b47e;
  color: #24b47e;
}

.timeline__event--type3 .timeline__event__title {
  color: #24b47e;
}

.notes:before {
  background: #aff1b6;
  border-color: #24b47e;
}

.notes:after {
  background: #24b47e;
}

.notes .timeline__event__date {
  color: #aff1b6;
}

.notes .timeline__event__icon {
  background: #24b47e;
  color: #24b47e;
}

.notes .timeline__event__title {
  color: #24b47e;
}


.timeline__event:last-child:after {
  content: none;
}

@media (max-width: 786px) {
  .timeline__event {
    flex-direction: column;
  }

  .timeline__event__icon {
    border-radius: 4px 4px 0 0;
  }
}
</style>