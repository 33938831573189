<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <h1 class="fm-header-text">Before Survey</h1>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4 mt-4">
    <p>Thank you for taking the time to complete this questionnaire. The purpose is to be able to help you identify your
      progress over the next 6 months.
      <br>
      As per the terms and conditions, we may share anonymised information with Rape Crisis England and Wales for
      evaluation purposes. If you have any questions or concerns, please don’t hesitate to get in touch with Beki</p>
      </b-row>
    <Survey :survey="survey"/>
    </b-container>
  </b-container>
</template>

<script>
import 'survey-core/defaultV2.css';
import {Model} from 'survey-core';
import {Survey} from 'survey-vue-ui';
import api from "@/api";

// const SURVEY_ID = 1;

const surveyJson = {
  elements: [
    {
      isRequired: true,
      type: "matrix",
      name: "identify_statements",
      title: "Please indicate whether you identify with the following statements",
      columns: [{
        text: "Yes"
      }, {
        text: "No"
      }, {
        text: "Sometimes"
      },{
        text: "Not applicable"
      }],
      rows: [{
        value: "I feel confident in my day-to-day job",
        text: "I feel confident in my day-to-day job"
      }, {
        value: "I have power and can make decisions in my role",
        text: "I have power and can make decisions in my role"
      }, {
        value: "I feel able to share my power with the people I work with",
        text: "I feel able to share my power with the people I work with"
      }, {
        value: "I am able to identify skills gaps and feel confident in addressing these",
        text: "I am able to identify skills gaps and feel confident in addressing these"
      },{
        value: "I know what my career goals are",
        text: "I know what my career goals are"
      },{
        value: "When I identify an issue, I feel confident that I will find a solution",
        text: "When I identify an issue, I feel confident that I will find a solution"
      },{
        value: "I have a comfortable work/life balance",
        text: "I have a comfortable work/life balance"
      },{
        value: "I know where to get support if I need it",
        text: "I know where to get support if I need it"
      },{
        value: "If an opportunity arose for a new role, I would feel confident in going for it",
        text: "If an opportunity arose for a new role, I would feel confident in going for it"
      },{
        value: "I feel connected with the Rape Crisis England and Wales network",
        text: "I feel connected with the Rape Crisis England and Wales network"
      }
      ]
    }, {
      type: "rating",
      name: "app_process",
      title: "How did you find the application process?",
      mininumRateDescription: "Bad",
      maximumRateDescription: "Excellent",
      isRequired: true

    },{
      type: "rating",
      name: "match_process",
      title: "How did you find the matching process?",
      mininumRateDescription: "Bad",
      maximumRateDescription: "Excellent",
      isRequired: true
    },
    {
      name: "app_match_feedback",
      title: "What would you do to improve the application or matching processes?",
      type: "text",
      defaultValue: ""
    },
    {
      type: "rating",
      name: "mentoring_hopeful",
      title: "Has the matching process made you more or less hopeful about the mentoring experience?",
      mininumRateDescription: "Less hopeful",
      maximumRateDescription: "More hopeful",
      isRequired: true
    },
    {
      name: "other_comments_feedback",
      title: "Any other comments or feedback?",
      type: "text",
      defaultValue: ""
    },
    {
      name: "survey_name",
      title: "survey_name",
      type: "text",
      isRequired: true,
      defaultValue: "Before Survey",
      visibleIf: "{rating} contains 'null'"
    },
  ]
};

export default {
  name: 'Before Survey',
  title: 'Feminist Mentoring - Before Survey',
  components: {
    Survey
  },
  data() {
    const survey = new Model(surveyJson);
    survey.clearInvisibleValues = false;
    survey.onComplete.add(this.alertResults);
    return {
      survey
    }
  },
  methods: {
    alertResults(sender) {
      const results = JSON.stringify(sender.data);
      api.post("savesurvey/", results)
          .then(() => {
            this.$parent.reload_notifications = true;
          })
    }
  },
}
</script>
<style scoped>
.image_header {
  height: 200px;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/survey-header.jpg') no-repeat right;
  background-size: cover;
}

.sd-root-modern {
  background-color: #EEF0F2;
}


</style>