// eslint-disable-next-line no-unused-vars
// const store = new Vuex.Store({
import Vue from 'vue';
import Vuex from "vuex";
import axios from "axios";
import jwt_decode from "jwt-decode";
import VueCookies from "vue-cookies";
Vue.use(Vuex);
Vue.use(VueCookies);
// eslint-disable-next-line no-unused-vars
import { envbaseUrl } from "@/store/env_vars";

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';


export const store = new Vuex.Store({
    state: {
        isAuthenticated: false,
        isAdmin: false,
        // isAuthenticated: false,
        jwt: window.$cookies.get('token'),
        access: window.$cookies.get('access'),
        refresh: window.$cookies.get('refresh'),
        isMentor: false,
        isMentee: false,
        endpoints: {
            obtainJWT: 'auth/get_token',
            refreshJWT: 'auth/refresh_token',
            verifyJWT: 'auth/verify_token',
            baseUrl: envbaseUrl
            // baseUrl: 'http://localhost:8000/'
        }
    },
    mutations: {
        setAuthUser(state, {
            authUser,
            isAdmin,
            isMentee,
            isMentor,
            isAuthenticated,
            access,
            refresh
        }) {
            Vue.set(state, 'authUser', authUser)
            Vue.set(state, 'isAdmin', isAdmin)
            Vue.set(state, 'isMentee', isMentee)
            Vue.set(state, 'isMentor', isMentor)
            Vue.set(state, 'isAuthenticated', isAuthenticated)
            window.$cookies.set('access', access)
            window.$cookies.set('refresh', refresh)
            // localStorage.setItem('authUser', authUser)
            // localStorage.setItem('isAdmin', isAdmin)
            // localStorage.setItem('isMentee', isMentee)
            // localStorage.setItem('isMentor', isMentor)
            // localStorage.setItem('isAuthenticated', isAuthenticated)
            // localStorage.setItem('access', access)
            // localStorage.setItem('refresh', refresh)
        },
        updateToken(state, newToken) {
            console.log("Update Token");
            console.log(state);
            console.log(newToken);
            localStorage.setItem('t', newToken);
            state.jwt = newToken;
        },
        removeToken(state) {
            console.log("Remove Token");
            // localStorage.removeItem('t');
            // localStorage.removeItem('user');
            state.jwt = null;
        },
    },
    actions: {
        obtainToken(username, password) {
            console.log("Obtain Token");
            const payload = {
                username: username,
                password: password
            }
            axios.post(this.state.endpoints.obtainJWT, payload)
                .then((response) => {
                    this.commit('updateToken', response.data.token, response.data.user);
                })
                .catch((error) => {
                    console.log(error);
                })
        },
        refreshToken() {
            const payload = {
                token: this.state.jwt
            }
            axios.post(this.state.endpoints.refreshJWT, payload)
                .then((response) => {
                    console.log("Refresh Token");
                    this.commit('updateToken', response.data.token, response.data.user);
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        inspectToken() {
            const token = Vue.$cookies.get('access')
            if (token) {
                console.log("DECODING TOKEN");
                const decoded = jwt_decode(token);
                const exp = decoded.exp
                const orig_iat = decoded.orig_iat
                if (exp - (Date.now() / 1000) < 1800 && (Date.now() / 1000) - orig_iat < 628200) {
                    this.dispatch('refreshToken')
                } else if (exp - (Date.now() / 1000) < 1800) {
                    // DO NOTHING, DO NOT REFRESH
                } else {
                    // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
                }
            }
        }
    }

})