<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <h1 class="fm-header-text">Training Feedback</h1>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4 mt-4">
        <p>We welcome your feedback so that we can improve any training for future feminist mentoring programmes. As per
          the terms and conditions, we may share anonymised information with Rape Crisis England and Wales for
          evaluation purposes. If you have any questions or concerns, please don’t hesitate to get in touch with
          Beki.</p>
      </b-row>
      <Survey :survey="survey"/>
    </b-container>
  </b-container>
</template>

<script>
import 'survey-core/defaultV2.css';
import {Model} from 'survey-core';
import {Survey} from 'survey-vue-ui';
import api from "@/api";

const surveyJson = {
  elements: [
    {
      isRequired: true,
      type: "matrix",
      name: "identify_statements",
      title: "Please score each of the following statements based on your experience",
      columns: [{
        text: "Strongly disagree"
      }, {
        text: "Disagree"
      }, {
        text: "Neither agree nor disagree"
      }, {
        text: "Agree"
      }, {
        text: "Strongly Agree"
      }, {
        text: "Not applicable"
      }],
      rows: [{
        value: "I was satisfied with the communication prior to the training",
        text: "I was satisfied with the communication prior to the training"
      }, {
        value: "The training content was informative and engaging",
        text: "The training content was informative and engaging"
      }, {
        value: "The training activities were suitable",
        text: "The training activities were suitable"
      }, {
        value: "The trainer was knowledgeable ",
        text: "The trainer was knowledgeable "
      }, {
        value: "Time was well managed and paced",
        text: "Time was well managed and paced"
      }, {
        value: "I felt supported during the training",
        text: "I felt supported during the training"
      }, {
        value: "I appreciated being able to catch up or re-watch the recorded training sessions",
        text: "I appreciated being able to catch up or re-watch the recorded training sessions"
      }, {
        value: "If needed, I feel comfortable to reach out for further support on a 1:1 basis",
        text: "If needed, I feel comfortable to reach out for further support on a 1:1 basis"
      }
      ]
    }, {
      name: "training_likes",
      title: "What did you like most about the training?",
      type: "text",
      isRequired: true
    },{
      name: "training_improvements",
      title: "What were some things you felt could be improved?",
      type: "text",
      isRequired: true
    },
    {
      name: "recommend",
      title: "Would you recommend this training to a colleague?",
      type: "radiogroup",
      choices: [
        {text: "Yes"},
        {text: "No"},
        {text: "Maybe"}
      ],
      isRequired: true
    },

    {
      type: "rating",
      name: "training_rating",
      title: "How would you rate the training overall?",
      mininumRateDescription: "Bad",
      maximumRateDescription: "Excellent",
      isRequired: true

    },
    {
      name: "other_comments",
      title: "Any other comments or feedback?",
      type: "text",
      isRequired: true
    },
    {
      name: "survey_name",
      title: "survey_name",
      type: "text",
      isRequired: true,
      defaultValue: "Training Feedback",
      visibleIf: "{rating} contains 'null'"
    },
  ]
};

export default {
  name: 'Before Survey',
  title: 'Feminist Mentoring - Before Survey',
  components: {
    Survey
  },
  data() {
    const survey = new Model(surveyJson);
    survey.clearInvisibleValues = false;
    survey.onComplete.add(this.alertResults);
    return {
      survey
    }
  },
  methods: {
    alertResults(sender) {
      const results = JSON.stringify(sender.data);
      api.post("savesurvey/", results)
          .then(() => {
            this.$parent.reload_notifications = true;
          })
    }
  },
}
</script>
<style scoped>
.image_header {
  height: 200px;
  background-position: right bottom;
  border-bottom: 5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/survey-header.jpg') no-repeat right;
  background-size: cover;
}

.sd-root-modern {
  background-color: #EEF0F2;
}


</style>