<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <b-container fluid="xl">
      <div class="contact-us-box">
        <b-card
            img-src="/static/images/contact-header.jpg"
            img-top>
          <template #header>
            <h4 class="mb-0">
              <b-icon icon="envelope" style="width: 20px; height: 20px;"></b-icon>
              Bug Reports & Feedback
            </h4>
          </template>
          <b-card-text v-if="show">
            <p>Submit Bug Reports or Feedback for the Feminist Mentoring Platform using the form below. Your account
              details will be associated with the report to aid with troubleshooting or further discussion.
              <br>
              We can't guarantee every enhancement or feature suggestion will be implemented, but they will all be
              reviewed and assessed against our current plans.
            </p>
            <b-form @submit="onSubmit" v-if="show">



              <b-form-group
                  id="input-group-1"
                  label="Organisation"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="form.org"
                    placeholder="Enter Organisation or Company name if relevant"
                ></b-form-input>
              </b-form-group>


              <b-form-group id="input-group-3" label="Message" label-for="contact-us-text"
                            description="Please do not include personal or sensitive information in your message. Max 700 characters.">
                <b-form-textarea
                    id="contact-us-text"
                    placeholder="Your message to us"
                    v-model="form.message"
                    :state="form.message.length <= 700 && form.message.length >= 20"
                    rows="6"
                ></b-form-textarea>
              </b-form-group>
              <br>
              <b-button type="submit" variant="fm">Submit</b-button>
            </b-form>
          </b-card-text>
          <b-card-text v-if="!show">
            <p>Thanks for your message, we'll be in touch to discuss</p>
          </b-card-text>

        </b-card>

      </div>
    </b-container>
  </b-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import api from "../api.js";

export default {
  name: "Contact",
  title: "Beki Osborne - Contact Us",
  data() {
    return {
      form: {
        email: '',
        name: '',
        message: '',
        org: ''
      },
      show: true,
    }
  },
  mounted: function () {

  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      let formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      formData.append('org', this.form.org);
      formData.append('message', this.form.message);
      api.post("contact/", formData, {})
          .then((response) => {
            console.log("Submitting");
            console.log(response.data);
            this.show = false;
          })
    },

  },
}
</script>

<style scoped>
.contact-us-box {
  padding-top: 30px;
}

.container-fluid {
  max-width: 70vw;
  min-width: 70vw;
}

input {
  margin-bottom: 20px;
}

</style>
