<template>
  <b-container>
    <br>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
        <b-breadcrumb-item :to="{ name: 'ProgramList' }" class="fm-link">Programmes</b-breadcrumb-item>
        <b-breadcrumb-item active>Manage Programme</b-breadcrumb-item>
      </b-breadcrumb>

      <b-form @submit="onSubmit">
        <b-row class="pb-3">
          <b-col cols="2">
            <b-form-select v-model="form.state" size="sm" class="mt-3">
              <b-form-select-option value="Planned">Planned</b-form-select-option>
              <b-form-select-option value="Active">Active</b-form-select-option>
              <b-form-select-option value="Completed">Completed</b-form-select-option>
              <b-form-select-option value="Cancelled">Cancelled</b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="4">
            <b-form-group id="input-group-13" label="Programme Name" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="form.name"
                  type="text"
                  required
                  placeholder="Name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group id="input-group-13" label="Programme Description" label-for="input-13">
              <b-form-input
                  id="input-13"
                  v-model="form.description"
                  type="text"
                  required
                  placeholder="Description"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="pb-3">
          <b-col>
            <b-form-group id="input-group-1" label="Start Date" label-for="input-1">
              <b-form-datepicker
                  id="input-1"
                  v-model="form.start"
                  required
                  placeholder="Start Date"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-group-2" label="End Date" label-for="input-2">
              <b-form-datepicker
                  id="input-2"
                  v-model="form.end"
                  required
                  placeholder="End Date"
              ></b-form-datepicker>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="input-group-14" label="Registration Code" label-for="input-14">
              <b-form-input
                  id="input-14"
                  v-model="form.registration_code"
                  type="text"
                  disabled
                  placeholder="- Code Generated After Saving -"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <br>
        <h5>Programme Documents</h5>
        <p>Add or remove documents you've uploaded to a programme here</p>
        <div>
          <b-form-select v-model="docselected" :options="items" value-field="id" text-field="name"
                         @change="saveDoc"></b-form-select>
        </div>
        <b-table responsive small :fields="fields" :items="programmedocuments" v-bind:post="id" class="text-center"
                 :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
          <template v-slot:cell(state)="row">
            <b-icon v-b-tooltip.hover title="Draft" v-if="row.item.state === 'DRAFT'" icon="pencil" scale="1.5"
                    variant="secondary"></b-icon>
            <b-icon v-b-tooltip.hover title="Preview" v-else-if="row.item.state === 'PREVIEW'" icon="eye" scale="1.5"
                    variant="primary"></b-icon>
            <b-icon v-b-tooltip.hover title="Scheduled" v-else-if="row.item.state === 'Planned'" icon="calendar"
                    scale="1.5"
                    variant="primary"></b-icon>
            <b-icon v-b-tooltip.hover title="Live" v-else-if="row.item.state === 'Active'" icon="broadcast" scale="1.5"
                    variant="success"></b-icon>
            <b-icon v-b-tooltip.hover title="Sold" v-else-if="row.item.state === 'SOLD'" icon="award" scale="1.5"
                    variant="dark"></b-icon>
            <b-icon v-b-tooltip.hover title="Ended" v-else-if="row.item.state === 'ENDED'" icon="calendar-fill"
                    scale="1.5"
                    variant="dark"></b-icon>
          </template>

          <template v-slot:cell(manage)="row">
            <b-button v-b-tooltip.hover title="Manage Programme" variant="dark" size="sm"
                      @click="handleClick(row.item, 'ManageProgramme')" class="mr-1 mt-1">
              <b-icon-pencil-square></b-icon-pencil-square>
            </b-button>
            <b-button v-b-tooltip.hover title="Programme Data" v-if="row.item.state === 'LIVE'" variant="dark" size="sm"
                      @click="handleClick(row.item, 'ManageProgramme')" class="mr-1 mt-1" disabled>
              <b-icon-clipboard-data></b-icon-clipboard-data>
            </b-button>
          </template>
        </b-table>

        <b-row class="pb-3 float-end">
          <b-col>
            <b-button class="mr-2" type="submit" variant="primary">Save</b-button>
          </b-col>
        </b-row>
      </b-form>

    </div>
  </b-container>

</template>

<script>
import api from '../../api.js'

export default {
  // props: ['id'],
  data() {
    return {
      form: {
        start: '',
        end: '',
        name: '',
        description: '',
        state: 'Planned',
        registration_code: ''
      },
      documentlist: [],
      programmedocuments: [],
      docselected: '',
      options: [
        {value: 'Manual', text: 'Manual'},
        {value: 'Automatic', text: 'Automatic'},
      ],
      userlist: [],
      carlist: [],
      reserve_options: [
        {text: 'Set Reserve', value: true},
        {text: 'No Reserve', value: false},
      ],
      validationError: false,
      validationOutput: "",
      items: [],
      id: '',
      sortBy: "name",
      sortDesc: false,
      fields: ['name', 'created_at', 'updated_at', 'document']

    }
  },

  mounted: function () {
    // this.getCars();
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.getAuction(this.$route.params.id);
    }
    this.getDocuments();
    this.getProgDocs();


  },
  methods: {
    saveDoc() {
      let formData = new FormData();
      formData.append('id', this.docselected);

      api.post("progdoc/" + this.$route.params.id + "/", formData)
          .then((response) => {
            console.log("Submitting");
            console.log(response.data);
            // this.$router.push({path: '/admin/programmes'})
            this.getProgDocs();
          })
    },
    getAuction(id) {
      console.log("getting programme details");
      api.get("/programme/" + id)
          .then((response) => {
            console.log(response.data);
            this.form.name = response.data.name;
            this.form.description = response.data.description;
            this.form.start = response.data.start;
            this.form.end = response.data.end;
            this.form.state = response.data.state;
            this.form.registration_code = response.data.registration_code;
          })
    },
    getProgDocs() {
      api.get("/progdoc/" + this.id)
          .then((response) => {
            console.log(response.data);
            this.programmedocuments = response.data;
          })
    },
    getDocuments() {
      api.get("/documents/")
          .then((response) => {
            console.log(response.data);
            this.items = response.data;
          })
    },
    handleClick(record, target) {
      console.log(record);
      this.$router.push({name: target, params: {id: record, auc_id: this.$route.params.id}})
    },


    onSubmit(evt) {
      if (evt) {
        evt.preventDefault()
      }
      // this.form.seller = [this.form.seller]
      // alert(JSON.stringify(this.form))
      let formData = new FormData();
      formData.append('id', this.$route.params.id);
      formData.append('state', this.form.state);
      formData.append('name', this.form.name);
      formData.append('description', this.form.description);
      formData.append('start', this.form.start);
      formData.append('end', this.form.end);
      formData.append('registration_code', this.form.registration_code);
      if (this.$route.params.id) {
        api.put("programme/" + this.$route.params.id + "/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/programmes'})
            })
      } else {
        api.post("programme/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              this.$router.push({path: '/admin/programmes'})
            })
      }
    }
  }
}
</script>
<style>

</style>