<template>
  <b-container>
    <br>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
      <b-breadcrumb-item active>Users</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
          <b-button-group class="mx-2">
            <b-button @click="filterTable">All</b-button>
            <b-button @click="filterTable">Mentor</b-button>
            <b-button @click="filterTable">Mentee</b-button>
          </b-button-group>
          <b-button-group>
            <b-button type="submit" variant="fm" :to="{ name: 'Bulk Load' }">Bulk Load Users</b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <!--    <b-row class="mt-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4">-->
    <!--      <b-col v-for="user in userlist" v-bind:key="user.id" class="mb-4">-->
    <!--        <UserCard :first_name="user.first_name" :last_name="user.last_name" :jobtitle="user.jobtitle"-->
    <!--                  :mentee="user.mentee" :mentor="user.mentor">-->
    <!--        </UserCard>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <br>
    <b-table responsive small :fields="fields" :items="userlist" v-bind:post="id" class="text-center"
             :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template v-slot:cell(ismentor)="row">
        <b-icon v-b-tooltip.hover title="Preview" v-if="row.item.baseuserprofile.mentor" icon="check" scale="2"
                variant="success"></b-icon>
      </template>
      <template v-slot:cell(ismentee)="row">
        <b-icon v-b-tooltip.hover title="Draft" v-if="row.item.baseuserprofile.mentee" icon="check" scale="2"
                variant="success"></b-icon>
      </template>

      <template v-slot:cell(sendemail)="row">
        <b-button v-b-tooltip.hover title="Send Activation Email" variant="success" size="sm" v-if="!row.item.is_active && row.item.baseuserprofile.activation_mail_sent"
                  @click="handleClick(row.item, 'ManageProgramme')" class="mr-1 mt-1">
          <b-icon-envelope-fill></b-icon-envelope-fill>
        </b-button>
        <b-button v-b-tooltip.hover title="Send Activation Email" variant="dark" size="sm" v-if="!row.item.is_active && !row.item.baseuserprofile.activation_mail_sent"
                  @click="handleClick(row.item, 'ManageProgramme')" class="mr-1 mt-1">
          <b-icon-envelope-fill></b-icon-envelope-fill>
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import api from "../../api.js"

// import UserCard from "@/components/admin/UserCard";

export default {
  name: "ShowUsers",
  components: {
    // 'UserCard': UserCard
  },
  data() {
    return {
      userlist: null,
      items: [],
      id: [],
      sortBy: 'name',
      sortDesc: false,
      fields: [
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'baseuserprofile.jobTitle',
          label: "Job Title"
        },
        {
          key: 'is_active',
          label: "Acc. Activated?"
        },
        {
          key: 'progname',
          label: "Registered Programme"
        },
        {
          key: 'ismentor',
          label: "Mentor"
        },
        {
          key: 'ismentee',
          label: "Mentee"
        },
        {
          key: 'baseuserprofile.activation_mail_sent',
          label: "activation_mail_sent",
          thClass: 'd-none', tdClass: 'd-none'
        },
        {
          key: 'sendemail',
          label: "Send Activation Email?"
        }
      ],
    }
  },
  methods: {
    handleClick(record) {

      let formData = new FormData();
      formData.append('id', record.id);

      api.post("send_activation/", formData)
          .then(() => {
            this.$parent.makeToast("Activation email sent to "+record.name);
            this.getUsers();
          })
      // this.$router.push({name: target, params: {id: record.id}})
    },
    getUsers(filter="all") {
      api.get("user_list/"+filter+"/")
          .then((response) => {
            this.userlist = response.data
            console.log(this.userlist);
          })
    },
    filterTable(event) {
      console.log((event.srcElement.innerHTML).toLowerCase())
      this.getUsers((event.srcElement.innerHTML).toLowerCase())
    }
  },
  created: function () {
    this.getUsers();
  }
}
</script>

<style scoped>

</style>