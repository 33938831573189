<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <h1 class="fm-header-text">Equalities Monitoring</h1>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4 mt-4">
        <p>Collecting equality data will allow us to make better sense of who is accessing mentoring support and the
          gaps that we need to address in possible future programmes. Any answers you submit are stored anonymously.
          <br><br>
          This questionnaire is anonymous and as per the terms and conditions, we may share anonymised information
          with Rape Crisis England and Wales for evaluation purposes. If you have any questions or concerns, please
          don’t hesitate to get in touch with Beki.</p>
      </b-row>

      <Survey :survey="survey"/>
    </b-container>
  </b-container>
</template>

<script>
import 'survey-core/defaultV2.css';
import {Model} from 'survey-core';
import {Survey} from 'survey-vue-ui';
import api from "@/api";

// const SURVEY_ID = 1;

const surveyJson = {
  elements: [{
    name: "gender",
    title: "What is your self-identified gender identity?",
    type: "radiogroup",
    choices: [
      {text: "Female (including trans female)"},
      {text: "Non-binary"},
      {text: "Intersex"},
      {text: "Other"},
      {text: "Prefer not to say"},
    ],
    isRequired: true
  },
    {
      name: "gender_other",
      title: "Please enter your gender identity:",
      type: "text",
      visibleIf: "{gender} contains 'Other'",
      isRequired: true
    },
    {
      name: "gender_birth",
      title: "Is the gender you identify with the same as your gender assigned at birth?",
      type: "radiogroup",
      choices: [
        {text: "Yes"},
        {text: "No"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "age_range",
      title: "What is your age range?",
      type: "radiogroup",
      choices: [
        {text: "16-24"},
        {text: "25-34"},
        {text: "35-44"},
        {text: "45-54"},
        {text: "55-64"},
        {text: "65+"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "ethnicity",
      title: "What is your ethnicity? Please make a selection you feel comfortable identifying with, or write your own description in \"Other\"",
      type: "radiogroup",
      choices: [
        {text: "Asian or Asian British"},
        {text: "Black, African, Caribbean or Black British"},
        {text: "Mixed or multiple ethnic groups"},
        {text: "White"},
        {text: "Other"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "ethnicity_other",
      title: "Please enter your ethnicity:",
      type: "text",
      visibleIf: "{ethnicity} contains 'Other'",
      isRequired: true
    },
    {
      name: "disability",
      title: "Do you consider yourself to have a disability or health condition?",
      type: "radiogroup",
      choices: [
        {text: "Yes"},
        {text: "No"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "lgbtq",
      title: "Do you consider yourself to identify as LGBTQ+?",
      type: "radiogroup",
      choices: [
        {text: "Yes"},
        {text: "No"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "religion",
      title: "What is your religion or belief?",
      type: "radiogroup",
      choices: [
        {text: "Buddhist"},
        {text: "Christian"},
        {text: "Hindu"},
        {text: "Jewish"},
        {text: "Muslim"},
        {text: "Sikh"},
        {text: "No religion or belief"},
        {text: "Other"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "ethnicity_other",
      title: "Please enter your religion or belief:",
      type: "text",
      visibleIf: "{religion} contains 'Other'",
      isRequired: true
    },
    {
      name: "caring",
      title: "Do you have caring responsibilities?",
      type: "radiogroup",
      choices: [
        {text: "Primary carer of a child/children (under 18)"},
        {text: "Primary carer of disabled child/children (under 18)"},
        {text: "Primary carer of disabled adult (18 and over)"},
        {text: "Primary carer of older person"},
        {text: "Secondary carer (another person carries out the main caring role)"},
        {text: "None"},
        {text: "Other"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "caring_other",
      title: "Please enter your caring responsibilities:",
      type: "text",
      visibleIf: "{caring} contains 'Other'",
      isRequired: true
    },
    {
      name: "qualification",
      title: "What is the highest level of qualification you hold?\n",
      type: "radiogroup",
      choices: [
        {text: "Doctorate/level 8 qualification"},
        {text: "Masters/level 7 qualification"},
        {text: "Degree /level 6 qualification"},
        {text: "Foundation degree/level 5 qualification"},
        {text: "Certificate of higher education/higher apprenticeship/level 4 qualification"},
        {text: "A level/advanced apprenticeship/level 3 qualification"},
        {text: "GCSE/apprenticeship/level 1 or 2 qualification"},
        {text: "Not applicable"},
        {text: "Other"},
        {text: "Prefer not to say"},
      ],
      isRequired: true
    },
    {
      name: "qualification_other",
      title: "Please enter your qualification:",
      type: "text",
      visibleIf: "{qualification} contains 'Other'",
      isRequired: true
    },

  ]
};

export default {
  name: 'Equalities Survey',
  title: 'Feminist Mentoring - Equalities Survey',
  components: {
    Survey
  },
  data() {
    const survey = new Model(surveyJson);
    survey.onComplete.add(this.alertResults);

    return {
      survey
    }
  },
  methods: {
    alertResults(sender) {
      const results = JSON.stringify(sender.data);
      // alert(results);
      api.post("saveequalities/", results)
          .then(() => {
            this.$parent.reload_notifications = true;
          })
      // saveSurveyResults(
      //   "https://your-web-service.com/" + SURVEY_ID,
      //   sender.data
      // )
    }
  },
}

// function saveSurveyResults(url, json) {
//   fetch(url, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     },
//     body: JSON.stringify(json)
//   })
//   .then(response => {
//     if (response.ok) {
//       // Handle success
//     } else {
//       // Handle error
//     }
//   })
//   .catch(error => {
//     // Handle error
//   });
// }
</script>
<style scoped>
.image_header {
  height: 200px;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/survey-header.jpg') no-repeat right;
  background-size: cover;
}

.sd-root-modern {
  background-color: #EEF0F2;
}

</style>