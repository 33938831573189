<template>
  <div id="app">
    <Navigation></Navigation>
    <router-view/>
    <Footer></Footer>
    <CookieBanner
        v-if="showBanner"
        @hideBanner="showBanner = false"
    />
  </div>
</template>

<script>

import Navigation from "@/components/layout/Navigation";
import {checkAuthenticated} from "@/auth";
import Footer from "@/components/layout/Footer.vue";
import CookieBanner from './components/CookieBanner.vue'
import { bootstrap } from 'vue-gtag'

export default {
  title () {
    return `Feminist Mentoring -  ${this.pageTitle}`
  },
  name: 'app',
  components: {
    Navigation,
    Footer,
    CookieBanner
  },
  data: function() {
    return {
      showBanner: !(localStorage.getItem('accept_cookies')),
      reload_notifications: false,
    }
  },
  methods: {
    makeToast(msg, append = false) {
      this.toastCount++
      this.$bvToast.toast(msg, {
        title: 'Notification',
        autoHideDelay: 3500,
        variant: "fm",
        appendToast: append
      })
    },
    createDocumentTree(documents) {
      let toggler = document.getElementsByClassName("caret");
      let i;

      for (i = 0; i < toggler.length; i++) {
        toggler[i].addEventListener("click", function() {
          this.parentElement.querySelector(".nested").classList.toggle("active");
          this.classList.toggle("caret-down");
        });
        let docCount = 0;

        if (toggler[i].innerHTML === "Agreements") {
          console.log(documents.length)
          console.log(documents)
          if (documents.length === 0) {
            console.log("No Agreements");
          }
          for (let i = 0; i < documents.length; i++) {
            if (documents[i].category === "agreement") {
              docCount = docCount + 1
              let list = document.getElementById('agreement-docs')
              let li = document.createElement("li");
              li.classList.add("document-link")
              li.appendChild(document.createTextNode(documents[i].name));
              li.addEventListener('click', this.getDocument)
              list.appendChild(li)
            }
          }
          if(docCount === 0){
            let list = document.getElementById('agreement-docs')
            let li = document.createElement("li");
            li.classList.add("no-document-placeholder")
            li.appendChild(document.createTextNode("No documents available at the moment"));
            list.appendChild(li)
          }
          docCount = 0
        }
        else if (toggler[i].innerHTML === "Training") {
          for (let i = 0; i < documents.length; i++) {
            if (documents[i].category === "training") {
              docCount = docCount + 1
              let list = document.getElementById('training-docs')
              let li = document.createElement("li");
              li.classList.add("document-link")
              li.appendChild(document.createTextNode(documents[i].name));
              li.addEventListener('click', this.getDocument)
              list.appendChild(li)
            }
          }
          if(docCount === 0){
            let list = document.getElementById('training-docs')
            let li = document.createElement("li");
            li.classList.add("no-document-placeholder")
            li.appendChild(document.createTextNode("No documents available at the moment"));
            list.appendChild(li)
          }

        }

      }
    },
  },
  beforeMount() {
    checkAuthenticated();
    let h = document.querySelector('html');
    h.setAttribute('lang', 'en');
    if (localStorage.getItem('accept_cookies') === "1") {
      // eslint-disable-next-line no-unused-vars
      bootstrap().then((gtag) => {
        // all done!
      })
    }
  },
}


</script>

<style>

@font-face {
  font-family: 'DuniaKita';
  src: url('/static/fonts/DuniaKita.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


#app {
  font-family: 'Quattrocento Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*margin-top: 110px;*/
  background-color: #EEF0F2 !important;
}

.fm-link a {
  text-decoration: none !important;
  color: #cc1d90 !important;
}

.fm-link a:hover {
  text-decoration: none !important;
  color: #c72c93 !important;
}

a.fm-link {
  text-decoration: none !important;
  color: #832161 !important;
}

a.fm-link:hover {
  text-decoration: none !important;
  color: #c72c93 !important;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: lightpink !important;
}

.dropdown-item a {
  color: #212529;
  text-decoration: none;
}

.document-link {
  cursor: pointer;
  color: darkmagenta;
}

.document-link:hover {
  color: deeppink;
}

.btn-fm {
  background-color: #840a4dc2 !important;
  color: white !important;
}


.btn-fm:hover {
  background-color: rgba(192, 16, 113, 0.76) !important;
  color: white !important;
}

.fm-header {
  color: #832161;
}

.container-xl {
  margin-bottom: 40px;
}
.header {
  /*height: 120px;*/
  display: grid;
  background-position: right bottom;
  border-bottom:5px solid #022b36;
  background-size: cover;
  background-color: #022b36;
  color: #ffffff;
  align-items: center;

}

.header p {
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
}

@media screen and (max-width: 860px) {
  p.fm-header-text {
    color: #d568a5;
    position: relative;
    font-size: 50px;
    font-family: "DuniaKita";
  }
}

@media screen and (min-width: 860px) {
  p.fm-header-text {
    color: #d568a5;
    position: relative;
    font-size: 80px;
    font-family: "DuniaKita";
    font-weight: normal;
  }
}
.fm-font-size-3 {
  font-size: 3rem !important;
}

.fm-font-size-2 {
  font-size: 2rem !important;
}


.header h1 {
  text-align: center;
  font-size: 30px;
  margin-bottom: 0px;
}

@media screen and (max-width: 860px) {
  h1.fm-header-text {
    color: #d568a5;
    position: relative;
    font-size: 50px;
    font-family: "DuniaKita";
  }
}

@media screen and (min-width: 860px) {
  h1.fm-header-text {
    color: #d568a5;
    position: relative;
    font-size: 80px;
    font-family: "DuniaKita";
    font-weight: normal;
    padding-top: 15px;
  }
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
blockquote{
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.75);
  font-size: 1.1em;
  width:90%;
  margin:50px auto !important;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 15px 1.2em 60px;
  border-left:8px solid #db6aab ;
  line-height:1.6;
  position: relative;
  background: #e0e0e0;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color: #db6aab;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  margin-top:1em;
}

.btn-contact {
  background-color: #840a4dc2 !important;
  color: white !important;
  margin-top: 20px;
  --bs-btn-padding-x: 1rem !important;
  --bs-btn-padding-y: 0.5rem !important;
  font-size: 20px !important;
}


.btn-contact:hover {
  background-color: rgba(192, 16, 113, 0.76) !important;
  color: white !important;
}

.tns-nav {
  text-align: center;
  margin: 10px 0;
}

.tns-nav > .tns-nav-active {
  background: #797979 !important;
}

.tns-nav > [aria-controls] {
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background: #ddd;
  border: 0;
}

.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer !important;
}

.tns-outer [data-action] {
  display: none;
}

.tns-controls {
  display: none;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

button, select {
  text-transform: none;
}

.toast {
  -ms-flex-preferred-size: 350px;
  flex-basis: 350px;
  max-width: 350px;
  font-size: .875rem;
  background-color: hsla(0,0%,100%,.85);
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.ml-auto, .mx-auto {
  margin-left: auto!important;
}

.b-toast-fm .toast .toast-body {
  background-color: rgba(205, 101, 159, 0.47);
  border-color: #cd659f !important;
  color: #000000;

}

.b-toast-fm .toast .toast-header {
  color: #FFFFFF;
  background-color: rgb(205, 101, 159);
  border-bottom-color: rgba(190, 229, 235, 0.85);
}

.b-toaster.b-toaster-top-right .b-toaster-slot, .b-toaster.b-toaster-top-left .b-toaster-slot, .b-toaster.b-toaster-top-center .b-toaster-slot, .b-toaster.b-toaster-top-full .b-toaster-slot {
  top: 1rem !important;
  right: 0.5rem !important;
}

#driver-popover-title {
  color: #ff9fd5
}

.driver-popover {
    background-color: rgb(10, 86, 108);
    color: white;
  }

.driver-popover-arrow {
  content: "";
  position: absolute;
  border: 5px solid rgb(10, 86, 108);
}

.driver-popover-arrow-side-bottom {
  bottom: 100%;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
}

.driver-popover-arrow-side-left {
  left: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}

.driver-popover-arrow-side-top {
  top: 100%;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.driver-popover-arrow-side-right {
  right: 100%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
}



.hub-page-tour {
  float: right;
  font-size: 15px;
}

.notification-nav a:hover, .user-nav a:hover {
  color: rgba(243, 39, 154, 0.85);
}

li.notification-nav a, li.user-nav a   {
  padding-top: 10px !important;
  padding-bottom: 5px !important;
  color: rgba(255, 255, 255, 0.77);
}



.dropdown-item a {
  color: #212529;
  text-decoration: none;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.list-group-item, .dropdown-item {
  position: relative;
  display: block;
  //padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  padding-left: 16px !important;
  padding-right: 16px !important;
  color: black !important;
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.dropdown-item:hover, .dropdown-item:focus {
  color: black !important;
  background-color: lightpink !important;
}

@media (min-width: 1080px) {
  .navbar-expand-fm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-fm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-fm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-fm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-fm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-fm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-fm .navbar-toggler {
    display: none;
  }
  .navbar-expand-fm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-fm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-fm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

#document-list span:hover {
  color: #840a4dc2;
}

.no-document-placeholder {
  font-size: 13px;
  color: #7a7a7a;
  font-style: italic;
}

.input-error {
  color: red;
  margin: 5px;
  display: block;
}

.checkbox-label label {
  margin-left: 4px;
}

.notification-nav li {
  min-width: 400px;
}

.sd-root-modern {
  background-color: #EEF0F2;
}
</style>