<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="consult-header">
      <div class="header-text">
        <h1 class="fm-header-text">Consulting</h1>
        <p class="text-center">
          <b-button variant="contact" target="_blank" to="contact">
            Get in Touch
          </b-button>
        </p>
      </div>
    </div>
    <b-container fluid="xl" class="w-100 mw-100">
      <b-row>
        <b-col class="text-center fs-4 intro-text mb-5">As a social impact sector leader myself, I know that we can’t be experts in absolutely everything.
          Sometimes you need to ask for an extra pair of hands, or an external perspective to help steer you in the
          right direction.
        </b-col>
      </b-row>

      <div>
        <b-card no-body class="overflow-hidden" style="max-width: 1000px;">
          <b-row no-gutters>
            <b-col md="7">
              <b-card-img src="/static/images/women-consult.jpg" alt="Image" ></b-card-img>
            </b-col>
            <b-col md="5">
              <b-card-body>
                <b-card-text>
                  <p class="text-center fm-header-text fm-font-size-2" >How I can help</p>
                  I provide affordable consultancy to organisations of any size and make up with the sole aim to make your
                  life easier.
                  <br><br>
                  My work is grounded in intersectional feminist principles and I take a non-directional approach, which
                  means I won’t tell you what to do but I will help you to explore your options and identify the solution that
                  works best for you and the people you work with.                </b-card-text>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </div>



      <blockquote>
        Beki's gentle, clear and thoughtful approach was just what we needed. Beki really listened to what we needed to
        explore as an organisation, and used an interesting and engaging range of resources and questions to keep us all
        thinking. In less assured hands, some of these topics could have felt scary or vulnerable, especially in a small
        team, but Beki held the space beautifully, even online. We have plenty to think about, but we have new tools,
        language and confidence - and permission to take our time and look after ourselves while we do it! Thank you,
        Beki
        <span>Alison Breadon, Interim Chief Executive Officer, Everyday Wigan</span>
      </blockquote>
      <p class="mt-5 text-center fm-header-text fm-font-size-3" >My areas of expertise are;</p>
      <b-row no-gutters>

    <b-card-group deck>
        <b-card
            v-for="area in areas"
            overlay
            :title="area.title"
            :img-src="area.image"
            img-alt="Image"
            img-top
            tag="article"
            style="max-width: 20rem;"
            v-bind:key="area.title"
        >
        </b-card>

    </b-card-group>
      </b-row>
<!--<b-row>-->
<!--  <b-col class="intro-text">-->
<!--      My areas of expertise are;-->
<!--      <b-list-group>-->
<!--        <b-list-group-item>Organisational leadership and strategic development</b-list-group-item>-->
<!--        <b-list-group-item>People and volunteer management strategy, including change management</b-list-group-item>-->
<!--        <b-list-group-item>Team building, away day and workshop facilitation</b-list-group-item>-->
<!--      </b-list-group>-->
<!--  </b-col>-->
<!--</b-row>-->

<b-row>
  <b-col class="text-center fs-4 intro-text">
    If you’re unsure if I can help you, please contact me for a free 30-minute consultation. If I can’t help you, I’ll
    do my best to connect you with the right support!
    <p class="text-center">
      <b-button variant="contact" target="_blank" to="contact">
        Get in Touch
      </b-button>
    </p>
  </b-col>
</b-row>
    </b-container>
  </b-container>
</template>

<script>

export default {
  name: "Consulting",
  metaInfo: function () {
    return {
      title: "Beki Osborne - Feminist Mentoring & Consulting",
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: "Feminist grounded consulting for all organisations with expertise in leadership and strategic development, people and volunteer strategy and team building."},
        { name: 'og:site_name', content: "Beki Osborne - Feminist Mentoring & Consulting"},
        { name: 'og:title', content: "Beki Osborne - Feminist Mentoring & Consulting"},
        { name: 'og:url', content: "https://www.bekiosborne.com/consulting"},
        { name: 'og:type', content: "website"}
      ]
    }
  },
  data() {
    return {
      areas: [
        {
          title: "Organisational leadership and strategic development",
          image: '/static/images/leadership.jpg'
        },
        {
          title: "People and volunteer management strategy, including change management",
          image: '/static/images/peoplemanagement.jpg'
        },
        {
          title: "Team building, away day and workshop facilitation",
          image: '/static/images/teambuilding.jpg'
        }
      ]
    }
  }
}
</script>

<style scoped>
.image_header {
  height: 170px;
  background-position: right bottom;
  border-bottom: 5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/consulting.jpeg') no-repeat right;
  background-size: cover;
}

.header {
  margin-bottom: 0px;
}

.card-title {
  font-size: 1.7rem;
  line-height: 1.4;
  //text-transform: uppercase;
  font-weight: 400;
  color: white;
  letter-spacing: 1.5px;
  font-family: "DuniaKita";
  text-align: center;
}

.card-body {
  display: flex !important;
  align-items: center !important;
}

.card-img-top {
  filter: brightness(45%);
  border-radius: 10px;
}

a {
  text-decoration: none;
}

.consult-left {
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/women-consult.jpg') no-repeat bottom center;
  min-height: 400px;

}

.consult-right {
  background: linear-gradient(rgba(2, 43, 54, 0), rgba(2, 43, 54, 0)), url('/static/images/pointing.jpg') no-repeat right;
  min-height: 400px;
}

.consult-header {
  min-height: 400px;
  width: 100%;
  background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
  ),
  url('/static/images/consult-header.jpg') no-repeat left;
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

@media only screen and (max-device-width: 1366px) {
  .consult-header {
    background-attachment: scroll;
  }
}


.header-text {
  text-align: center;
  padding-top: 100px;
}

.fm-header-text {
  color: #d568a5;
}


.intro-text {
  max-width: 900px;
  margin: auto;
  padding-top: 50px;
}

.card {
  margin: auto;
  min-width: 14rem;
  border-radius: 11px;
}

.card-deck {
  margin-top: 10px;
}

.card-title:hover {
  color: pink;
}

</style>
