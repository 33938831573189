<template>
  <b-container>
    <br>
    <b-breadcrumb>
      <b-breadcrumb-item active>Admin</b-breadcrumb-item>
    </b-breadcrumb>

    <b-list-group>
      <b-list-group-item class="d-flex align-items-center" :to="{ name: 'ProgramList' }">
        <b-icon icon="calendar3-range-fill" aria-hidden="true" class="m-2"> </b-icon>Show all Programmes
<!--        <b-badge variant="dark">{{ this.counts.auctions }}</b-badge>-->
      </b-list-group-item>

      <b-list-group-item class="d-flex  align-items-center" :to="{ name: 'ManageProgramme' }">
        <b-icon icon="calendar2-plus  " aria-hidden="true" class="m-2"> </b-icon>Create new Programme
      </b-list-group-item>

      <b-list-group-item class="d-flex align-items-center" :to="{ name: 'DocumentList' }">
        <b-icon icon="file-earmark" aria-hidden="true"  class="m-2"> </b-icon>  Manage Documents
      </b-list-group-item>

      <b-list-group-item class="d-flex align-items-center" :to="{ name: 'ShowUsers' }">
        <b-icon icon="person-fill" aria-hidden="true"  class="m-2"> </b-icon>  Show Users
      </b-list-group-item>

      <b-list-group-item class="d-flex align-items-center" :to="{ name: 'ContactRequests' }">
        <b-icon icon="envelope" aria-hidden="true"  class="m-2"> </b-icon>  Contact Requests
      </b-list-group-item>

      <b-list-group-item class="d-flex align-items-center" :to="{ name: 'NotificationList' }">
        <b-icon icon="lightbulb" aria-hidden="true"  class="m-2"> </b-icon>  Notification Manager
      </b-list-group-item>

<!--      <b-list-group-item class="d-flex  align-items-center" variant="danger" :to="{ name: 'TestData' }">-->
<!--        <b-icon icon="bug" aria-hidden="true" class="m-2"></b-icon>DEBUG: Manage Test Data-->
<!--      </b-list-group-item>-->

    </b-list-group>
<!--    <b-button @click="saves3">Save S3 Video</b-button>-->
  </b-container>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import api from "@/api";

export default {
  name: "Admin",
  data() {
    return {
    }
  },
  methods: {
    saves3() {
      api.get("/saveS3Video/")
          .then((response) => {
            console.log(response.data);
            this.items = response.data;
          })
    }


  }
}
</script>

<style scoped>

</style>