<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <div class="image_header"></div>
    <div class="header">
      <p class="fm-header-text">Feminist Mentoring Programme T&Cs</p>
    </div>
    <b-container fluid="xl">
      <b-row class="pt-4">
        <b-col cols="12" class="about-text">

          <h1 class="entry-title">Feminist Mentoring Programme Terms &#038; Conditions</h1>

          <div class="entry-content">

            <p><strong>Definitions</strong></p>


            <p><strong>The ‘Feminist Mentoring Programme’ is the name of the free and voluntary programme run by Beki
              Osborne</strong> <strong>for Rape Crisis England and Wales.</strong></p>


            <ul>
              <li>‘<strong>Mentor</strong>’ if you have offered to act as a mentor to a Mentee within the Feminist
                Mentoring Programme
              </li>


              <li>‘<strong>Mentee</strong>’ if you have asked for a Mentor to provide mentoring to you, the Mentee,
                within the Feminist Mentoring Programme
              </li>
            </ul>


            <p><strong>The Feminist Mentoring Programme</strong></p>


            <p>The duration of mentoring partnerships within the programme will be determined by mutual agreement
              between the Mentor and Mentee, however these typically last for 6 months. We expect a meeting to take
              place each month for a minimum of one hour, although the frequency, duration and location of meetings will
              be agreed within your mentoring partnership. This programme is offered at no cost to participants, is
              entirely voluntary and no costs or expenses are covered by the programme. You can withdraw at any time by
              contacting Beki Osborne through the <router-link class="fm-link" to="contact" >Contact Form</router-link>, or directly
              to her email address provided during registration.
            <p>Whilst this is a voluntary programme, we encourage Rape Crisis member centres to provide the Mentor or
              Mentee with time to participate within working hours or for these hours to contribute to their
              volunteering time for the organisation. As such, in your application we have asked you to confirm your
              line manager’s support for your participation and will provide you with a resource for your line manager
              to ensure reflection on your journey takes place within your line management supervision meetings to
              support your onward career progression.</p>


            <p>Participants must be a volunteer, member of staff or trustee at a Rape Crisis member centre in order to
              take part. Should one or more party leave their centre and the network altogether during the course of the
              programme, the mentoring may continue but you will not be able to apply for future programmes. The Mentor
              shall not at any time be obliged to provide or continue to provide any mentoring services to the Mentee,
              but if and to the extent that they do so, such services will be provided free of charge.</p>


            <p><strong>Liability</strong></p>


            <p>While the mentoring process will support the professional development of both the Mentor and Mentee, the
              Feminist Mentoring Programme should not be considered a substitute for professional judgement. The final
              responsibility for judgements and decisions made by participants, and the actions that flow from them, lie
              with participants.</p>


            <p>Supplying mentoring services does not represent financial, management or consultancy advice. The Mentor
              is not responsible for the accuracy or completeness of any information provided to the Mentee. The Mentee
              is advised to take suitable independent professional advice before acting on any information, suggestion,
              or guidance given by the Mentor.</p>


            <p>The Mentee acknowledges that any services provided by Beki Osborne and the Mentor are provided free of
              charge and in good faith.</p>
            <p>Beki Osborne is not liable to the Mentor, Mentee or to any third party for any loss, damage, costs or
              liabilities suffered as a result of this agreement, the existence of the relationships between the
              parties, the services provided or the act or omissions of the Mentor or Mentee.</p>


            <p><strong>Term</strong></p>


            <p>Whilst the Mentor and the Mentee remain engaged in the Feminist Mentoring programme, these Terms and
              Conditions will continue in force.</p>


            <p><strong>Matching</strong></p>


            <p>We aim to match Mentors and Mentees using the information provided on the Feminist Mentoring Programme
              application form. We will then use the information provided to share a profile of your potential match to
              you and ask you to confirm if you would like to go ahead within 14 calendar days. You are able to reject
              the match, but we cannot guarantee that another match will be offered.</p>


            <p>We are not able to match everybody who applies and will notify any unmatched applicants via email if this
              the case and will provide you with an opportunity to opt in to hear about future programmes.</p>

            <p>Whilst full support will be provided for participants to fully commit to the programme, this is a
              voluntary programme and sometimes things don’t work out for a range of reasons. If one person in the
              partnership drops out before training is completed, all reasonable endeavours will be made to find a
              suitable replacement. However, should a replacement not be found, or a person drops out of the programme
              during or after the training course, we are not able to continue your participation in this programme but,
              if you consent, we can keep your information on file for any future programmes.</p>


            <p>All best endeavours will be made to provide you with a match that will provide you with an independent,
              neutral and comfortable space. However, if any point you identify a conflict of interest within your
              mentoring partnership or you feel uncomfortable about your match for any other reason, as per the <a
                  href="/files/Conflict-of-Interest-Procedure.pdf" class="fm-link" target="_blank">Conflict
                of Interest Procedure</a>, please let Beki know through the <router-link class="fm-link" to="contact" >Contact Form</router-link>, or directly
              to her email address provided during registration.
            </p>


            <p><strong>Confidentiality</strong></p>


            <p>It is intended that mentoring spaces will be used for reflection and discussion and as such will largely
              be confidential. The Mentee and the Mentor will agree the confidentiality boundaries in their first
              session.</p>


            <p>This clause shall not apply in relation to any information that is already available in the public domain
              or if the person you are working with gives you cause to believe that:</p>


            <ul>
              <li>They may harm themselves, or someone else</li>
              <li>That they are involved in unsafe practice at their Rape Crisis member centre</li>
            </ul>


            <p>If you have any such concerns, please let Beki know through the <router-link class="fm-link" to="contact" >Contact Form</router-link>, or directly
              to her email address provided during registration, and she will support you to follow safeguarding best practice. In exceptional circumstances surrounding unsafe
              practice, it may be necessary for Beki to share any information or concerns with the membership team at
              Rape Crisis England and Wales as the administrator of the Rape Crisis National Service Standards.</p>


            <p>Neither the Mentee nor the Mentor may use any intellectual property of Beki Osborne in any of their
              publicity materials or in any announcement, without the prior written consent of Beki Osborne.</p>


            <p><strong>Mentees will:</strong></p>


            <ul>
              <li>Be respectful of your mentors time</li>


              <li>Be responsible for scheduling and arranging the meetings with your mentor</li>


              <li>Use your first meeting to draw up clear objectives for the partnership as well as practical details
              </li>


              <li>Listen to your mentor’s opinion but take responsibility for making your own choices</li>
            </ul>
            <p><strong>Mentors will:</strong></p>


            <ul>
              <li>Listen and keep in confidence the issues your mentee shares (subject to the confidentiality rules)
              </li>


              <li>Draw on your own experience to provide insight and signpost your mentee to further areas of support if
                required (e.g. coaching, counselling etc.)
              </li>


              <li>Attend regular supervision and reach out to Beki if they are unsure on how to proceed</li>


              <li>Use your first meeting to draw up clear objectives for the partnership as well as practical details
              </li>


              <li>Attend the three half day’s training provided, or make arrangements with Beki to catch up</li>


              <li>Be open and willing to learn, be challenged and explore your self and the structures around you</li>
            </ul>

            <p><strong>Privacy Policy</strong></p>


            <p>By participating in the mentoring programme, the Mentor and the Mentee agree they have been directed to
              the Privacy Policy of Beki Osborne, details of which can be found here:
              <router-link class="fm-link" to="privacypolicy" >Privacy Policy</router-link></p>



            <p>Information from the Feminist Mentoring Programme, including your application form and any feedback
              provided, may be shared:</p>


            <ol style="list-style-type:1">
              <li>With potential mentors/mentees (for matching purposes)</li>


              <li>Anonymously with Rape Crisis England and Wales (for administration and evaluation purposes)</li>
            </ol>

            <p><strong>Feedback and complaints</strong></p>


            <p>Any mentor or mentee can get in touch with Beki at any point during the programme by contacting Beki
              either through the <router-link class="fm-link" to="contact" >Contact Form</router-link>, or directly
            to her email address provided during registration.</p>


            <p>Beki Osborne is a member of the European Mentoring and Coaching Council. Their complaints procedure can
              be found <a class="fm-link" href="https://emccuk.org/Public/Public/About/Policies/Complaints_Procedure.aspx">here</a>.</p>


            <p>You will be provided with regular opportunities to provide feedback about your experience. Completion of
              these questionnaires are a requirement of the programme as it will enable us to continually improve the
              programme.</p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>

export default {
  name: "Feminist Mentoring - Terms and Conditions",
  title: "Feminist Mentoring - Terms and Conditions",
  data() {
    return {
      title: "Feminist Mentoring - Terms and Conditions"
    }
  },
  mounted: function () {

  },
  methods: {}
}
</script>

<style scoped>

.about-text {
  margin: auto;
  padding-top: 30px;
}

.about-image {
  text-align: center;
}

.image-border {
  border-radius: 30px;
  display: block;

  background-color: rgba(0, 0, 0, 0.72);
  border: 2px solid #012A36;
  margin: auto;
}

.fm-col {
  background-color: rgba(1, 42, 54, 0.11);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.blockquote {
  background-color: rgba(222, 107, 172, 0.24);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  text-align: center;
}

a.fm-link {
  text-decoration: none !important;
  color: #c5198a !important;
}

a.fm-link:hover {
  text-decoration: none !important;
  color: #ff1bb3 !important;
}

.page-header {
  background-color: rgb(1, 42, 54);
  width: fit-content;
  color: #e66eb2;
  margin: 30px 0px 30px 30px;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 25px;
  background-image: url('/static/images/about-header.jpg');
}


*, :after, :before {
  box-sizing: border-box
}

.pull-left {
  float: left
}

.pull-right {
  float: right
}

.clearfix:after, .clearfix:before {
  content: '';
  display: table
}

.clearfix:after {
  clear: both;
  display: block
}

.author-quote-wrap .author-quote,
.author-quote-wrap .author-photo:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.author-quote-wrap {
  color: #fff;
  width: 100%;
  overflow: hidden;
  max-width: 560px;
  min-height: 390px;
  margin: 50px auto 0;
  background: #832161;
  position: relative;
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, .22), 0 25px 55px 0 rgba(0, 0, 0, .21);
}

.author-quote-wrap .author-quote {
  display: none;
}

.author-quote-wrap .toggle-quote {
  display: none;
}

.author-quote-wrap .toggle-quote:checked + .author-quote {
  display: block;
}

.author-quote-wrap .author-quote a {
  color: inherit;
  text-decoration: none;
}

.author-quote-wrap .author-photo,
.author-quote-wrap .quote-content {
  height: 100%;
  position: relative;
}

.author-quote-wrap .author-photo {
  width: 140px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  quotes: '\201C''\201D''\2018''\2019';
}

.author-quote-wrap .author-photo:before {
  content: '';
  background: rgb(215, 105, 166);
}

.author-quote-wrap .author-photo:after {
  top: 15px;
  left: 60px;
  font-size: 72px;
  line-height: 72px;
  position: absolute;
  font-family: serif;
  content: open-quote;
}


.author-quote-wrap .quote-content {
  padding: 15px;
  width: calc(560px - 140px);
}

.quote-content .quote-like {
  font-size: 12px;
  font-weight: 400;
  padding: 4px 8px;
  border-radius: 15px;
  display: inline-block;
  background: rgba(0, 0, 0, .2);
}

.quote-content .quote-like .love {
  width: 18px;
  height: 10px;
  position: relative;
  display: inline-block;
}

.quote-content .quote-like .love:before,
.quote-content .quote-like .love:after {
  top: 0;
  left: 7px;
  content: '';
  width: 7px;
  height: 12px;
  background: #fff;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: 0 100%;
  border-radius: 20px 20px 0 0;
}

.quote-content .quote-like .love:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}


.quote-content .quote-text {
  font-size: 30px;
  font-weight: 900;
  line-height: 55px;
}

.quote-content .quote-author {
  bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
}

.quote-content .quote-author:before {
  content: '- ';
}

.container-xl {
  font-size: 20px;
}

.image_header {
  height: 300px;
  background-position: right bottom;
  border-bottom: 5px solid #022b36;
  background: linear-gradient(rgba(2, 43, 54, 0.1), rgba(2, 43, 54, 0.1)), url('/static/images/rcew-header.jpg') no-repeat right;
  background-size: cover;
}

</style>
