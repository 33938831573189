<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">

    <b-container fluid="xl">
      <div class="login-box" v-if="show">
        <b-card>
          <template #header>
            <h4 class="mb-0">
              Forgot your Password?
            </h4>
          </template>
          <b-card-text>
            <form>
            <b-form-group
                id="input-group-1"
                label="Enter the email address associated with your account"
                label-for="input-1"
                class="pb-3 w-75"
            >
              <b-form-input
                  id="input-1"
                  v-model="form.email"
                  type="email"
                  placeholder="Enter email"
                  class="w-100"
                  required
              ></b-form-input>
            </b-form-group>
            <template>
              <b-button size="md" variant="success" @click="forgotPassword" type="submit" class="m-2">
                Submit
              </b-button>
<!--              <b-button size="md" variant="danger" @click="cancel()">-->
<!--                Cancel-->
<!--              </b-button>-->
            </template><br>
            </form>
          </b-card-text>

        </b-card>

      </div>
      <div class="login-box" v-if="!show">
        <b-card>
          <template #header>
            <h4 class="mb-0">
              Forgot your Password?
            </h4>
          </template>
          <b-card-text>
            <p>If you've provided a valid email address, you will shortly receive an email to reset your password.</p>
          </b-card-text>

        </b-card>

      </div>


      <b-row class="pt-4 mt-4">




        </b-row>


      </b-container>

  </b-container>
</template>

<script>

import api from "@/api";

export default {
  name: "Home",
  data() {
    return {
      users: [],
      form: {
        email: '',
      },
      show: true
    }
  },
  metaInfo: function () {
    return {
      title: "Feminist Mentoring - Forgotten Password",
      meta: [
        { charset: 'utf-8' },
        { name: 'og:title', content: "Beki Osborne - Feminist Mentoring Login"},
        { name: 'og:url', content: "https://www.bekiosborne.com/"},
        { name: 'og:type', content: "website"}
      ]
    }
  },
  methods: {
    forgotPassword(evt) {
      evt.preventDefault()
      const payload = {
        email: this.form.email,
      }
      api.post("forgotpassword/", payload)
          .then(() => {
            this.show = false;
          })


    },
  }
}
</script>

<style scoped>

.login-box {
  padding-top: 30px;
  width: 80%;
  margin: auto;
}

.login-box .card-header {
  color: #f5f5f5;
  background-color: #022b36;
}

</style>
