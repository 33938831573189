import axios from 'axios'
// import Vue from 'vue'
import { store } from "@/store";
import Vue from "vue";
import router from "@/router";

let api = axios.create({
    baseURL: store.state.endpoints.baseUrl,
    xsrfHeaderName: 'X-CSRFToken',
    xsrfCookieName: 'csrftoken',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

api.defaults.timeout = 5000;

api.interceptors.request.use(function (config) {
    const token = Vue.$cookies.get('access');

    if ( store.state.isAuthenticated) {
        // const token = Vue.$cookies.get('access');
        config.headers.Authorization =  `Bearer ${token}`;
        if (store.state.authUser == undefined){
            store.commit("setAuthUser",
                {authUser: localStorage.getItem('authUser'), isAdmin: localStorage.getItem('isAdmin'), isAuthenticated: localStorage.getItem('isAuthenticated'),
                    access: localStorage.getItem('access'), refresh: localStorage.getItem('refresh')})
        }
    }
    // else if (token === null ||  token === undefined || token === "null" || token === "undefined") {
    //     console.log("COOKES NOT NULL. SETTING AUTH HEADER");
    //     const token = localStorage.getItem('access');
    //     config.headers.Authorization =  `Bearer ${token}`;
    //     store.commit("setAuthUser",
    //         {authUser: localStorage.getItem('authUser'), isAdmin: localStorage.getItem('isAdmin'), isAuthenticated: localStorage.getItem('isAuthenticated'),
    //             access: localStorage.getItem('access'), refresh: localStorage.getItem('refresh')})
    // }
    return config;
});

api.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else if(response.status === 401 && router.currentRoute.name !== 'login') {
            console.log("401, REDIRECTING!")
            console.log("RESPONSE - session expired");
            Vue.$cookies.remove('access');
            store.commit("setAuthUser",
                {authUser: null, isAdmin: null, isAuthenticated: false, access: null, refresh: null})
            router.replace({
                path: "/",
                query: { redirect: router.currentRoute.fullPath }
            });
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            console.log(error.status);
            switch (error.response.status) {
                case 400:
                    break;

                case 401:
                    if(router.currentRoute.name !== 'login') {
                        console.log("CASE 401")
                        console.log(router.currentRoute.name)
                        Vue.$cookies.remove('token');
                        store.commit("setAuthUser",
                            {authUser: null, isAuthenticated: false, isAdmin: false})
                        router.replace({
                            path: "/login",
                            query: { redirect: router.currentRoute.fullPath }
                        });
                    }

                    break;
                case 403:
                    router.replace({
                        path: "/login",
                        query: { redirect: router.currentRoute.fullPath }
                    });
                    break;
                case 404:
                    alert('page not exist');
                    break;
                case 502:
                    setTimeout(() => {
                        router.replace({
                            path: "/login",
                            query: {
                                redirect: router.currentRoute.fullPath
                            }
                        });
                    }, 1000);
            }
            return Promise.reject(error.response);
        }
    }
);

export default api