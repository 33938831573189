<template>
  <b-container>
    <br>
    <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
      <b-breadcrumb-item active>Notifications</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
    <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
      <b-button-group class="mx-1">
        <b-button variant="success" :to="{ name: 'ManageSurvey'}"><b-icon icon="file-plus" aria-hidden="true"></b-icon>  New Survey Notification</b-button>
      </b-button-group>
    </b-button-toolbar>
      </b-col>

    </b-row>
    <br>
    <b-table responsive small :fields="fields" :items="items" v-bind:post="id" class="text-center" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template v-slot:cell(status)="row">
        <b-button v-b-tooltip.hover title="Send Notification" v-if="row.item.in_app_sent === false" variant="danger" size="sm" @click="generateNotification(row.item)" class="mr-1 mt-1">
          <b-icon-envelope-fill></b-icon-envelope-fill>
        </b-button>
        <b-button disabled v-b-tooltip.hover title="Send Notification" v-if="row.item.in_app_sent === true" variant="success" size="sm" class="mr-1 mt-1">
          <b-icon-envelope-fill></b-icon-envelope-fill>
        </b-button>

        <b-icon v-else-if="row.item.in_app_sent === true" icon="x-lg" scale="1.5"
                variant="danger">X</b-icon>
      </template>

      <template v-slot:cell(manage)="row">
        <b-button v-b-tooltip.hover title="Manage Notification" variant="dark" size="sm" @click="handleClick(row.item, 'ManageSurvey')" class="mr-1 mt-1">
          <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
      </template>
<!--      <template v-slot:cell(delete)="row">-->
<!--        <b-button v-b-tooltip.hover title="Delete Document" variant="danger" size="sm" @click="deletePrompt(row.item)" class="mr-1 mt-1">-->
<!--          <b-icon-trash></b-icon-trash>-->
<!--        </b-button>-->
<!--      </template>-->
    </b-table>
    <div ref="gantt"></div>
    <b-modal id="delete-confirmation" title="Confirm Deletion" v-model="showDelPrompt">
      <p class="my-4">Are you sure you want to delete the following file?<br>
        It will be removed from all programmes it is associated with and then<br>
        deleted.<br><br>
        Name - {{ this.deleteTargetName }}<br>
        Description - {{ this.deleteTargetDescription}}</p>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="danger"
              size="sm"
              class="float-right"
              @click="deleteDocument"
          >
            Delete File
          </b-button>
          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="showDelPrompt=false"
              style="margin-right:30px;"
          >
            Cancel
          </b-button>
        </div>
      </template>

    </b-modal>

  </b-container>
</template>

<script>
import api from "../../api.js"
// import {gantt} from "dhtmlx-gantt";

export default {
  data() {
    return {
      showDelPrompt: false,
      id: [],
      sortBy: "name",
      sortDesc: false,
      deleteTargetId: '',
      deleteTargetName: '',
      deleteTargetDescription: '',
      items: [],
      fields: ['created_at', 'type', 'survey', 'headline', 'text', 'in_app', 'status', 'manage']
    }
  },
  mounted: function () {
    this.getNotifications();
  },
  methods: {
    generateNotification(items) {
      let formData = new FormData();
      formData.append('id', items.id);

      api.post("send_notification/", formData)
          .then((response) => {
            console.log("Submitting");
            console.log(response.data);
            this.getNotifications();
          })
    },
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    getNotifications() {
      api.get("/notificationlist/")
          .then((response) => {
            console.log(response.data);
            this.items = response.data;
          })
    },
    handleClick(record, target) {
      console.log(record.id);
      this.id = record.id;
      this.$router.push({name: target, params: {id: record.id}})
    },
    deletePrompt(record) {
      console.log(record.id);
      this.deleteTargetId = record.id;
      this.deleteTargetName = record.name;
      this.deleteTargetDescription = record.description;
      this.showDelPrompt = true;
      // this.$bvModal.show('delete-confirmation')
      // this.$router.push({name: target, params: {id: record.id}})
    },
    deleteDocument() {
      console.log("DELETING DOCUMENT");
      api.delete("/documents/" + this.deleteTargetId)
          .then((response) => {
            console.log(response.data);
            this.getDocuments()
            this.showDelPrompt = false;
          })
    },
    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
<style>
.float-right {
  float: right!important;
}
</style>