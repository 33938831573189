<template>
  <b-container fluid="xl" class="w-100 mw-100 p-0 m-0">
    <b-container fluid="xl">
      <div class="accordion m-4" role="tablist">
        <h4>Account Activation</h4>
        <b-card no-body class="mb-1" v-if="this.activated">
          <b-card-body>
            Account successfully activated, please create a password for your account.<br>
            Both passwords must match to update.

          </b-card-body>
          <b-card-body>
            <label for="text-password">New Password</label>
            <ValidationProvider rules="password-min|password-upper|password-special" v-slot="{ errors }" :bails="false">
            <b-form-input type="password" id="password1" aria-describedby="password-help-block"
                          v-model="form.password1" class="w-75 mb-3" ></b-form-input>
              <ul>
                <li class="input-error" v-bind:key="error" v-for="error in errors">{{ error }}</li>
              </ul>
            </ValidationProvider>

            <label for="text-password">Confirm New Password</label>
            <b-form-input type="password" id="password2" aria-describedby="password-help-block"
                          v-model="form.password2" class="w-75 mb-3" ></b-form-input>
            <b-button size="md" variant="success" id="update-password" @click="changepassword" type="submit" class="m-2">
              Update
            </b-button>
          </b-card-body>
        </b-card>
        <b-card no-body class="mb-1" v-if="this.checking">
          <b-card-body>Checking Activation Link</b-card-body>
        </b-card>
        <b-card no-body class="mb-1" v-if="this.error">
          <b-card-body>Invalid URL</b-card-body>
        </b-card>

        <!--    <b-card no-body class="mb-1">-->
        <!--      <b-card-header header-tag="header" class="p-1" role="tab">-->
        <!--        <b-button block v-b-toggle.accordion-2 variant="fm">Accordion 2</b-button>-->
        <!--      </b-card-header>-->
        <!--      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">-->
        <!--        <b-card-body>-->
        <!--          <b-card-text>{{ text }}</b-card-text>-->
        <!--        </b-card-body>-->
        <!--      </b-collapse>-->
        <!--    </b-card>-->

        <!--    <b-card no-body class="mb-1">-->
        <!--      <b-card-header header-tag="header" class="p-1" role="tab">-->
        <!--        <b-button block v-b-toggle.accordion-3 variant="fm">Accordion 3</b-button>-->
        <!--      </b-card-header>-->
        <!--      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">-->
        <!--        <b-card-body>-->
        <!--          <b-card-text>{{ text }}</b-card-text>-->
        <!--        </b-card-body>-->
        <!--      </b-collapse>-->
        <!--    </b-card>-->
      </div>
    </b-container>
  </b-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import api from "@/api";
import jwt_decode from "jwt-decode";
import {ValidationProvider, extend} from 'vee-validate';

extend('password-min', value => {
  if (value.length >= 8) {
    return true;
  }
  return 'Password must be at least 8 characters long';
});
extend('password-upper', value => {
  if (value.search(/[A-Z]/) > -1) {
    return true;
  }
  return 'Password must contain at least one uppercase character';
});
extend('password-special', value => {
  if (value.search(/[@#$%^&*]/) > -1) {
    return true;
  }
  return 'Password must contain at least one of these special characters @#$%^&*';
});


export default {
  components: {
    ValidationProvider
  },
  name: "profile",
  data() {
    return {
      checking: true,
      error: false,
      activated: false,
      form: {
        password1: '',
        password2: '',
      }
    }
  },
  mounted() {
    console.log(window.location.pathname)
    let activation_url = window.location.pathname.split("/");
    console.log(activation_url)
    api.get("/activate/"+activation_url[2]+"/"+activation_url[3]+"/")
        .then((response) => {
          console.log(response.data);
          this.checking = false
          this.activated = true

          const decoded = jwt_decode(response.data.access)
          this.$store.commit("setAuthUser",
              {
                authUser: decoded.name,
                isAdmin: decoded.isAdmin,
                isAuthenticated: true,
                isMentee: decoded.isMentee,
                isMentor: decoded.isMentor,
                access: response.data.access,
                refresh: response.data.refresh
              })
        })
        .catch(() => {
          this.checking = false
          this.error = true
        });
  },
  methods: {
    changepassword() {
      console.log("Changing password")
      let formData = new FormData();
      if (this.form.password1 === this.form.password2) {
        formData.append('password1', this.form.password1);
        formData.append('password2', this.form.password2);

        api.post("changepassword/", formData)
            .then((response) => {
              console.log("Submitting");
              console.log(response.data);
              if (this.$store.state.isMentee) {
                this.$router.push({name: 'MenteeHub'})
              } else if (this.$store.state.isMentor) {
                this.$router.push({path: '/mentorhub'})
              } else if (this.$store.state.isAdmin) {
                this.$router.push({path: '/admin'})
              }
              this.$parent.makeToast("Password successfully updated");
            })
      } else {
        this.$parent.makeToast("Passwords don't match, please ensure both passwords are the same");
      }

    },
    check_pass() {
      if ((document.getElementById('password1').value ===
          document.getElementById('password2').value) && document.getElementById('password1').value != "" ) {
        document.getElementById('update-password').classList.remove("disabled");
      } else {
        document.getElementById('update-password').classList.add("disabled");
      }
    },

  }
}
</script>

<style scoped>

</style>