<template>
  <b-container>
    <br>
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
      <b-breadcrumb-item active>Test Data</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <b-jumbotron>
          <h4>Master Test Data Controls</h4>
          <b-button class="m-2" variant="danger" @click="deleteUsers(0)">Delete all users (except for
            staff)
          </b-button>
          <b-button class="m-2" variant="danger" @click="deleteProgrammes(0)">Delete all programmes and registered users</b-button>
          <br><br>
          <b-button class="m-2" variant="success" @click="generateMentors(0)">Generate 5 test mentors randomly against programmes</b-button>
          <b-button class="m-2" variant="success" @click="generateMentees(0)">Generate 5 test mentees randomly against programmes</b-button>
          <br><br>
          <b-button class="m-2" variant="info" @click="generateProgrammes">Generate 5 test programmes</b-button>
        </b-jumbotron>
      </b-col>
    </b-row>
    <b-row>
      <h4>Programme Specific Test Data Controls</h4>
      <b-row             v-for="(value, id) in this.items"
                         :key="id">
        <b-card

        >
          <template #header>
            <h4 class="mb-0">
              {{ value['name'] }}
            </h4>
          </template>
          <b-button class="m-2" variant="danger" @click="deleteUsers(value['id'])">Delete all registered users
          </b-button>
          <b-button class="m-2" variant="danger" @click="deleteProgrammes(value['id'])">Delete program and users</b-button>
          <b-button class="m-2" variant="success" @click="generateMentors(value['id'])">Generate 5 test mentors</b-button>
          <b-button class="m-2" variant="success" @click="generateMentees(value['id'])">Generate 5 test mentees</b-button>        </b-card>

      </b-row>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/api";

export default {
  name: "TestData",
  data() {
    return {
      items: [],
      toastCount: null,
    }
  },
  methods: {
    deleteProgrammes(progId) {
      console.log("deleting programmes");
      api.get("deleteprogrammes/"+progId)
          .then((response) => {
            this.deleteresponse = response.data
            this.makeToast(this.deleteresponse)
            this.getProgrammes()
          })
    },
    deleteUsers(progId) {
      console.log("deleting all users");
      api.get("deleteusers/"+progId)
          .then((response) => {
            this.deleteresponse = response.data
            this.makeToast(this.deleteresponse)
          })
    },
    generateMentors(progId) {
      console.log("Generating 5 test mentors");
      console.log(progId)
      api.get("generatetestmentors/"+progId)
          .then((response) => {
            this.apiresponse = response.data
            this.makeToast("Successfully created 5 test mentors")
          })
    },
    generateMentees(progId) {
      console.log("Generating 5 test mentees");
      api.get("generatetestmentees/"+progId)
          .then((response) => {
            this.apiresponse = response.data
            this.makeToast("Successfully created 5 test mentees")
          })
    },
    generateProgrammes() {
      console.log("Generating 5 test programmes");
      api.get("createtestprogrammes/")
          .then((response) => {
            this.apiresponse = response.data
            this.makeToast("Successfully created 5 test programmes")
            this.getProgrammes();
          })
    },
    makeToast(msg, append = false) {
      this.toastCount++
      this.$bvToast.toast(msg, {
        title: 'Notification',
        autoHideDelay: 5000,
        variant: "info",
        appendToast: append
      })
    },
    getProgrammes() {
      api.get("/programmelist/")
          .then((response) => {
            console.log(response.data);
            this.items = response.data;
          })
    }

  },
  mounted: function () {
    this.getProgrammes();
  }
}
</script>

<style>
.toast:not(.show) {
  display: block !important;
}

.card-deck {
  justify-content: flex-start;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.card-deck .card {
  -ms-flex: 1 0 0%;
  flex: 1 0 0%;
  margin-right: 2px;
  margin-bottom: 0;
  margin-left: 2px;
}

.jumbotron {
  padding: 1rem 1rem;
  margin-bottom: 2rem;
  background-color: rgba(110, 6, 0, 0.22);
  border-radius: 0.3rem;
}

</style>