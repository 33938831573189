<template>
  <b-container>
    <br>
    <b-breadcrumb>
        <b-breadcrumb-item :to="{ name: 'Admin' }" class="fm-link">Admin</b-breadcrumb-item>
      <b-breadcrumb-item active>Programmes</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
    <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
      <b-button-group class="mx-1">
        <b-button variant="success" :to="{ name: 'ManageProgramme'}"><b-icon icon="plus-square" aria-hidden="true"></b-icon>  New Programme</b-button>
      </b-button-group>
    </b-button-toolbar>
      </b-col>
      <b-col>
    <b-button-toolbar aria-label="Toolbar with button groups and dropdown menu">
      <b-button-group class="mx-1">
        <b-button disabled>Planned</b-button>
        <b-button disabled>Live</b-button>
        <b-button disabled>Completed</b-button>
      </b-button-group>
    </b-button-toolbar>
      </b-col>
    </b-row>
    <br>
    <b-table responsive small :fields="fields" :items="items" v-bind:post="id" class="text-center" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
      <template v-slot:cell(state)="row">
        <b-icon v-b-tooltip.hover title="Draft" v-if="row.item.state === 'DRAFT'" icon="pencil" scale="1.5"
                variant="secondary"></b-icon>
        <b-icon v-b-tooltip.hover title="Preview" v-else-if="row.item.state === 'PREVIEW'" icon="eye" scale="1.5"
                variant="primary"></b-icon>
        <b-icon v-b-tooltip.hover title="Scheduled" v-else-if="row.item.state === 'Planned'" icon="calendar" scale="1.5"
                variant="primary"></b-icon>
        <b-icon v-b-tooltip.hover title="Live" v-else-if="row.item.state === 'Active'" icon="broadcast" scale="1.5"
                variant="success"></b-icon>
        <b-icon v-b-tooltip.hover title="Sold" v-else-if="row.item.state === 'SOLD'" icon="award" scale="1.5"
                variant="dark"></b-icon>
        <b-icon v-b-tooltip.hover title="Ended" v-else-if="row.item.state === 'ENDED'" icon="calendar-fill" scale="1.5"
                variant="dark"></b-icon>
      </template>

      <template v-slot:cell(manage)="row">
        <b-button v-b-tooltip.hover title="Manage Programme" variant="dark" size="sm" @click="handleClick(row.item, 'ManageProgramme')" class="mr-1 mt-1">
          <b-icon-pencil-square></b-icon-pencil-square>
        </b-button>
        <b-button v-b-tooltip.hover title="Programme Data" v-if="row.item.state === 'LIVE'" variant="dark" size="sm" @click="handleClick(row.item, 'ManageProgramme')" class="mr-1 mt-1" disabled>
          <b-icon-clipboard-data></b-icon-clipboard-data>
        </b-button>
      </template>
    </b-table>
    <div ref="gantt"></div>
  </b-container>
</template>

<script>
import api from "../../api.js"
// import {gantt} from "dhtmlx-gantt";

export default {
  data() {
    return {
      items: [],
      id: [],
      sortBy: "start",
      sortDesc: false,
      fields: ['name', 'description', 'start', 'end', 'state', 'manage']
    }
  },
  mounted: function () {
    this.getProgrammes();
  },
  methods: {
    showModal() {
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    getProgrammes() {
      api.get("/programmelist/")
          .then((response) => {
            console.log(response.data);
            this.items = response.data;
          })
    },
    handleClick(record, target) {
      console.log(record.id);
      this.id = record.id;
      this.$router.push({name: target, params: {id: record.id}})
    },
    onSubmit(evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.food = null
      this.form.checked = []
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
<style>

</style>